import { Injectable } from '@angular/core';
import { cloneDeep, isPlainObject, merge } from 'lodash-es';
import { Observable, Subject, map } from 'rxjs';

export interface ApplicationConfiguration {
    theme: string;
    productUrl: string;
    disableProductLink: boolean;
    companyUrl: string;
    culture: string;
    debug: boolean;
    oneTrustKey: string;
    oneTrustScriptTemplateUrl: string;
    oneTrustScriptConsentUrl: string;
    showLegalLinks: boolean;
    legalLinkBaseUrl: string;
    copyrightNoticeLbl: string;
    analyticsEnabled: boolean;
    moreProductInfoUrl?: string;
    logoutOnUnload?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private subject: Subject<ApplicationConfiguration> = new Subject<ApplicationConfiguration>();
    /**
     * Holds global app configuration object.
     *
     * @memberof AppConfigService
     */
    private _config: ApplicationConfiguration = {} as ApplicationConfiguration;

    /**
     * Setter of the appConfiguration for this provider, in an inmutable way.
     *
     * @memberof AppConfigService
     */
    private setConfig(config: ApplicationConfiguration) {
        // this._config = this.freeze(config); // Object.assign({}, this._config, config);
        this._config = Object.assign({}, this._config, config);
        this.subject.next(this._config);
    }

    /**
     * Getter of this configuration object.
     *
     * @memberof AppConfigService
     */
    public getConfig(): ApplicationConfiguration {
        return this._config;
    }

    /**
     * Getter to obtain the configuration partially or as a whole,
     * attending to the path passed in as argument.
     * Path is a string of the desired property/properties separated by a dot.
     * Return the search property value or undefined.
     *
     * @param {*} path
     * @returns
     * @memberof AppConfigService
     */
    public get(path: any) {
        if (typeof path === 'string') {
            let value: any = this._config;
            const props = path.split('.');

            for (let i = 0; value && i < props.length; i++) {
                value = value[props[i]];
            }

            return value;
        } else {
            return this._config;
        }
    }

    public getAsync<T>(path: string): Observable<T> {
        return this.subject
            .asObservable()
            .pipe(map(() => this.get(path)));
    }

    /**
     * Update current configuration object, merging the object passed in
     * as argument.
     *
     * @param {*} config
     * @memberof AppConfigService
     */
    public updateConfig(config: any) {
        if (this._config && isPlainObject(config)) {
            const tmp = cloneDeep(this._config);
            this._config = this.freeze({ ...tmp, ...config });
        }
    }

    /**
     * Helper function to freeze an object recursively.
     *
     * @param {*} object
     * @returns
     * @memberof AppConfigService
     */
    private freeze(object: any) {
        Object.getOwnPropertyNames(object).forEach(key => {
            const prop = object[key];
            if (typeof prop === 'object' && prop !== null && !Object.isFrozen(prop)) {
                this.freeze(prop);
            }
        });
        return Object.freeze(object);
    }

    public setEnvConfig(appConfig: any, hostname?: string): Promise<ApplicationConfiguration | void> {
        //Load  Env configuration
        const envName = appConfig.env2load || hostname || window.location.host;
        const envData = appConfig.environments[envName] || this.mergeEnvConfigs(appConfig.environments, envName);
        return this.resolveEnvironmentConfig(appConfig, envData)
            .then(config => this.setConfig(config));
    }

    private mergeEnvConfigs(environments: any, host: string): any {
        const envsArr: any[] = Object.entries(environments)
            .filter(function (itemArr) {
                return host.includes(itemArr[0]);
            })
            .map(function (itemArr) {
                return itemArr[1];
            });

        return Object.assign.apply(null, [{}, ...envsArr]);
    }

    private resolveEnvironmentConfig(
        cfg: ApplicationConfiguration,
        local?: ApplicationConfiguration | null | undefined): Promise<ApplicationConfiguration> {
        return Promise.resolve(merge({}, cfg, (local || {})));
    }
}
