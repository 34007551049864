import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { isPlainObject } from 'lodash-es';
import { cultureInfoResolver } from './culture-info-resolver';

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    private readonly cultureInfo: any = {};

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public setCultureInfo(newData: any): void {
        if (isPlainObject(newData)) {
            for (const key in newData) {
                if (Object.prototype.hasOwnProperty.call(newData, key)) {
                    this.cultureInfo[key] = newData[key];
                }
            }

            if (this.cultureInfo.language) {
                this.document.documentElement.setAttribute('lang', this.cultureInfo.language);
            }
        }
    }

    public getCultureInfo() {
        return this.cultureInfo;
    }

    public get(key: string): any {
        if (this.cultureInfo[key]) {
            return this.cultureInfo[key];
        } else {
            return this.cultureInfo;
        }
    }

    public getCulture(): string {
        return this.get('culture');
    }

    public getRegion(): string {
        return this.get('region');
    }

    public getLanguage(): string {
        return this.get('language');
    }

    public getLanguageTag(): string {
        return this.get('languageTag');
    }

    public updateCultureInfo(supported: string[], fallback: string, langCode: string): void {
        this.setCultureInfo(
            this.cultureInfoResolver(supported, fallback, langCode)
        );
    }

    public equals(a: string, b: string): boolean {
        const _b = b || this.cultureInfo.culture;
        if (!a || !_b) {
            return false;
        }
        return a.replace('_', '-') === _b.replace('_', '-');
    }

    private cultureInfoResolver(supported: Array<any>, fallback: any, langCode?: string){
        return cultureInfoResolver(supported, fallback, langCode);
    }
}
