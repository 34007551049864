export const keyCodes = {
    TAB: 9,
    ENTER: 13,
    END: 35,
    HOME: 36,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    F5: 116,
};
