


/**
 * Take an string with placehoders and an array of tokens as arguments and returns
 * a new string with tokens in place of each placeholder.
 *
 * @Example
 * For the input str: 'Lorem {0} ipsum dolor sit {1} amet consectetur {3} adipiscing eli.'
 * and tokens arr: ['aaaaaaa', 'bbbbbbb', 'cccccccc']
 *
 * The result of invoking:
 *
 *  strReplacer('Lorem {0} ipsum dolor sit {1} amet consectetur {3} adipiscing eli.', ['aaaaaaa', 'bbbbbbb', 'cccccccc']);
 *
 *  will give "'Lorem aaaaaaa ipsum dolor sit bbbbbbb amet consectetur cccccccc adipiscing eli.'" as result.
 *
 * @export
 * @param {*} str
 * @param {*} o
 * @returns
 */
export function strReplacer(str: string, o = []): string {
    return str.replace(
        /\{([^{}]*)\}/g,
        function (a, b) {
            const r = o[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
}
