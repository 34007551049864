import { AnalyticsToolService } from '@services/analytics-tool/analytics-tool.service';
import { isPlainObject} from 'lodash-es';
import { OrganizationTypes } from '@shared/interfaces';
import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { HttpCommonHeadersService } from '../http/common-headers.service';


@Injectable({ providedIn: 'root' })
export class UserService {
    isAuth: boolean = false;
    expiration: number = -1;
    organization: any = null;
    permissions: any = null;
    credential: any = null;
    authToken: any = null;
    csrfToken: any = null;
    ssotoken: any = null;
    reportTypeSelected: any = null;
    raw: any = {};
    appVersion: any;
    numericAppVersion: any;

    constructor(
        private analyticsToolService: AnalyticsToolService,
        private window: WindowRefService,
        private httpCommonHeadersService: HttpCommonHeadersService,
    ) {}

    public isAuthenticated(): boolean {
        return this.isAuth;
    }

    public isSSOAuthenticated(): boolean {
        return this.ssotoken !== null;
    }

    public isImpersonated(): boolean {
        return !!(this.credential?.impersonatingOrgId);
    }

    public startImpersonation(): void {
        this.credential.startImpersonation = true;
    }

    public isStartingImpersonation(): boolean {
        return !!(this.credential?.startImpersonation);
    }

    public setUserData(response: any, authToken?: string | null): void {
        const userData = response.data;

        if (userData) {
            this.organization = userData['organization'] || this.organization;
            this.permissions = userData['permissions'] || this.permissions;
            this.credential = userData['user'] || this.credential;

            if (this.isImpersonated()) {
                this.analyticsToolService.registerImpersonation(this.credential);
            } else if (this.credential && this.organization) {
                this.analyticsToolService.registerLogin(this.credential, this.organization);
            } else {
                // SONAR
            }

            this.raw = {...this.raw, ...userData};

            this.setHeadersData(response.headers, authToken);
        }
        // This should be the last sentence to launch the login event with the data set
        this.setAuth(true);

        this.reportTypeSelected = null;
    }

    /**
     * Get/Set user full name (firstName + ' ' + lastName)
     * @param {String} firstName If present, sets user's firstName.
     * @param {String} lastName If present, sets user's lastName.
     * @return {string} Returns user's full name. If parameters, returns new full name value.
     */
    public getFullName(firstName: string, lastName: string): string {
        let fullname = '';
        if (this.credential) {
            if (firstName) {
                this.credential.firstName = firstName;
            }
            if (lastName) {
                this.credential.lastName = lastName;
            }
            fullname = this.credential.firstName + ' ' + this.credential.lastName;
        }
        return fullname;
    }

    public getFirstName(): string {
        return this.credential?.firstName || '';
    }

    public getEmail(): string {
        return this.credential?.email || '';
    }

    public clearUserData(): void {
        this.expiration = -1;
        this.organization = null;
        this.permissions = null;
        this.credential = null;
        this.csrfToken = null;
        this.authToken = null;
        this.appVersion = null;
        this.numericAppVersion = null;
        this.reportTypeSelected = null;
        // This should be the last sentence to launch the login event with the data set
        this.setAuth(false);
    }

    public stringify(): any {
        if (isPlainObject(this.raw)) {
            return JSON.stringify(this.raw);
        }
        return this.raw;
    }

    public getCredentials(): any {
        return this.credential;
    }

    public getOrganization(): any {
        return this.organization;
    }

    public getOrganizationName(): string {
        return this.organization?.name || '';
    }

    public getOrganizationId(): any {
        return this.organization?.id;
    }

    public getOrganizationType(): string {
        return this.organization?.type;
    }

    public getOrganizationCurrency(): string {
        return this.organization?.currency;
    }

    public getOrganizationConfig(): any {
        const data = this.getOrganizationData();
        return data?.config || null;
    }

    public getOrganizationSubtype(): OrganizationTypes | string {
        const type = this.getOrganization().type;
        switch (type) {
            case OrganizationTypes.SUPER:
                return OrganizationTypes.MASTER;
            case OrganizationTypes.MASTER:
                return OrganizationTypes.PROVIDER;
            case OrganizationTypes.PROVIDER:
                return OrganizationTypes.CUSTOMER;
            case OrganizationTypes.CUSTOMER:
                return OrganizationTypes.AGGREGATOR;
            default:
                return 'not_defined';
        }
    }

    public getOrganizationRelatives(): any {
        return {
            'service:provider': this.getServiceProvider(),
            'customer': this.getCustomer(),
            'end_customer': this.getEndCustomer(),
        };
    }

    public getClusterProfiles(): any {
        const org = this.getOrganization();
        return org && org.clusterProfiles || null;
    }

    public getServiceProvider(): string {
        const org = this.getOrganization();
        return org && org.providerId || '';
    }

    public getCustomer(): string {
        const org = this.getOrganization();
        if (org?.type === OrganizationTypes.CUSTOMER) {
            return org.id;
        } else if (org?.type === OrganizationTypes.AGGREGATOR) {
            return org.parentId;
        } else {
            return '';
        }
    }

    public getEndCustomer(): string {
        const org = this.getOrganization();
        if (org && org.type === OrganizationTypes.AGGREGATOR) {
            return org.id;
        } else {
            return '';
        }
    }

    public getServiceName(): any {
        return this.credential?.serviceName || null;
    }

    public getPermission(namespace: string): any {
        let permission = this.permissions;
        if (namespace && permission && permission[namespace]) {
            permission = permission[namespace];
        }
        return permission;
    }

    public hasMonetaryAccess(): void {
        console.warn('userService.hasMonetaryAccess() unimplemented');
        //return this.get('monetaryDataAccess');
    }

    public getPublicTokens(): {csrf: any, auth: any} {
        const result = {
            csrf: this.csrfToken,
            auth: this.authToken,
        };
        return result;
    }

    public getAppVersion(): {full: any, numeric: any} {
        return {
            full: this.appVersion,
            numeric: this.numericAppVersion,
        };
    }

    public setCsrfToken(token: any): void {
        this.csrfToken = token;
    }

    public get(): any {
        return this.raw;
    }

    public getRawData(): any {
        return this.raw;
    }

    public getSupplService(): any {
        return this.raw?.supplService;
    }

    public getUserData(): any {
        return this.raw?.user;
    }

    public getUserId(): string {
        return this.raw?.user?.id;
    }

    public getUserName(): string {
        return this.credential?.userName;
    }

    public getOrganizationData(): any {
        return this.raw?.organization || null;
    }

    public getBillingAccounts(): Array<any> {
        const billingAccounts = [];
        const orgData = this.getOrganizationData();
        if (orgData?.defaultBillingAccount) {
            billingAccounts.push(orgData.defaultBillingAccount);
        }
        if (Array.isArray(orgData?.billingAccounts)) {
            const billingAccountsData = orgData.billingAccounts[0]?.$className === 'M2M.model.BillingAccountEl'
                ? orgData.billingAccounts.map((bAcc: any) => bAcc.data)
                : orgData.billingAccounts;
            billingAccounts.push(...billingAccountsData);
        }

        return billingAccounts;
    }

    public getOrganizationTimezone(): string {
        const orgData = this.getOrganizationData();
        return orgData?.billingCycleStart?.timezone;
    }

    public getUserTimezone(): string {
        return this.credential?.timezone || '';
    }

    public getUserRoles(): any {
        return this.credential.roles || [this.credential.role];
    }

    public userHasRole(role: any): boolean {
        return this.getUserRoles().includes(role);
    }

    public userHasAllRoles(roles: any): boolean {
        return roles.every((role: any) => this.getUserRoles().includes(role));
    }

    public userHasSomeRole(roles: any): boolean {
        return roles.some((role: any) => this.getUserRoles().includes(role));
    }

    public getUserOriginalType(): string {
        const userData = this.getUserData();
        let orgId = userData.impersonatingOriginalOrgId;
        if (!orgId) {
            orgId = userData.organizationId;
        }
        const orgIdParts = orgId?.split('-');
        if (orgIdParts?.length) {
            return orgIdParts[0];
        } else {
            return '';
        }
    }

    public getI18nConfig(): {thousandSeparator: string, decimalSeparator: string} {
        const orgData = this.getOrganizationData();
        return {
            thousandSeparator: orgData?.config?.thousandSeparator || '.',
            decimalSeparator: orgData?.config?.decimalSeparator || ',',
        };
    }

    /**
     * Indicate whether or not a user belongs to a customer organization that belongs to the
     * category of coordinator or Headquater of a multinational.
     *
     * @returns
     * @memberof UserService
     */
    public isMultinationalHq(): boolean {
        const multinational = this.organization.multinational;
        return !!(multinational?.hq);
    }

    /**
     * Returns the list of organization belonging to same multinational as this user
     * if it were the case that the user belongs to a HQ organization.
     *
     * @returns
     * @memberof UserService
     */
    public getMultinationalOrgs(): Array<any> {
        const multinational = this.organization.multinational;
        return multinational && Array.isArray(multinational.customers) ?  multinational.customers : [];
    }

    public setOrganizationConfig(key: string, value: any): void {
        this.raw.organization.config[key] = value;
    }

    /**
     * Provides all the corresponding organization ids for the
     * current user logged, including parent and grandparent organization ids.
     *
     * An object can be passed in as second parameter to override any of the ids.
     *
     * @param {*} organizationData
     * @param {*} overrides
     * @return {*}
     * @memberof BaseOrderFormController
     */
    public getHieralchicalOrgIdInfo(overrides: any): any {
        const organizationData = this.getOrganization();
        const isCustomer = organizationData?.type === OrganizationTypes.CUSTOMER;
        const isProvider = organizationData?.type === OrganizationTypes.PROVIDER;

        if (!isCustomer && !isProvider) {
            return null;
        }

        let result = {
            customerId: null,
            leadingObId: null,
            hostingObId: null,
        };

        if (isCustomer) {
            result.customerId = organizationData.id;
            if (organizationData.parentIsEnabler === false) {
                result.leadingObId = organizationData.parentId;
                result.hostingObId = organizationData.parentServiceProviderEnablerId;
            } else {
                result.hostingObId = organizationData.parentId;
            }
        } else {
            if (organizationData.isEnabler === false) {
                result.leadingObId = organizationData.id;
                result.hostingObId = organizationData.serviceProviderEnablerId;
            } else {
                result.hostingObId = organizationData.id;
            }
        }

        if (overrides) {
            result = {
                ...result,
                ...overrides,
            };
        }

        return result;
    }

    public getOrganizationKey(key: string): any{
        return this.organization[key];
    }

    public isLeadingOb(): boolean {
        const orgData = this.getOrganization();
        if (orgData) {
            return orgData.type === OrganizationTypes.PROVIDER
                && orgData.isEnabler === false;
        }
        return false;
    }

    public getCoreConfig(): any {
        return this.organization?.config?.coreConfig;
    }

    public getLiveStateTransitionPerms(): any {
        const store = this.window.nativeWindow.Ext.create('M2M.mc.store.LifeCycleStates');
        const disabledTransitions = [];
        const resource = M2M.PERM_NAMESPACE.mc + ':sim';

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_suspended_or_retired')) {
            disabledTransitions.push('RETIRED', 'SUSPENDED');
        }

        if (!M2M.isAllowed(resource, 'restore_suspended_lifecycle_status')) {
            disabledTransitions.push('RESTORE');
        }

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_deactivated')) {
            disabledTransitions.push('DEACTIVATED');
        }

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_inactive_new')) {
            disabledTransitions.push('INACTIVE_NEW');
        }

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_test')) {
            disabledTransitions.push('TEST');
        }

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_activation_ready')) {
            disabledTransitions.push('ACTIVATION_READY');
        }

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_activation_pendant')) {
            disabledTransitions.push('ACTIVATION_PENDANT');
        }

        if (!M2M.isAllowed(resource, 'change_lifecycle_status_to_active')) {
            disabledTransitions.push('ACTIVE');
        }

        disabledTransitions.forEach((filter) => {
            const i = store.findExact('id', filter);
            store.removeAt(i);
        });

        return store;
    }

    public hasMCPEnabled(): boolean {
      return  this.getBillingAccounts()
        .some(ba => ba.mcpEnabled || ba.mcp_enabled);
    }

    private setAuth(isAuth: boolean): void {
        this.isAuth = isAuth;
    }

    private setHeadersData(headers: any, authToken?: string | null): void {
        if (headers || authToken) {
            this.csrfToken = this.getHeader(headers, 'x-csrf-token');
            this.authToken = this.getHeader(headers, 'x-m2m-authtoken') || authToken;

            if (this.authToken) {
                const parts = this.authToken.split(',');
                if (parts.length > 1) {
                    this.authToken = parts[0];
                }
            }

            this.httpCommonHeadersService.setCommonHeaders(this.authToken, this.csrfToken);
        }
    }

    private getHeader(headers: any, headerName: string): string | null {
        // headers can be a function or an object, whether it is from angular HttpClient or from angularjs $http.
        if (headers && typeof headers.get === 'function') {
          return headers.get(headerName);
        }
        // Remove this when we remove angularjs $http from the project.
        else if (headers) {
          return headers(headerName);
        }
        return null;
    }

    public isEssentialOrg() {
        return this.getOrganizationType() === 'customer' &&
            this.getSupplService()?.selfManagementLevel === 'ESSENTIAL';
    }
}
