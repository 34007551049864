import { isFinite } from 'lodash-es';
import { LogoutService } from '@services/logout/logout.service';
import { UserService } from '../user/user.service';
import { AppConfigService } from '../app-config/app-config.service';
import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { firstValueFrom } from 'rxjs';

const DIVIDE_BY_THREE = 3;
const SECONDS_MULTIPLIER = 1000;

@Injectable({
    providedIn: 'root'
})
export class CheckExpiredService {
    private timeoutId!: number;
    private lastUsed!: number;
    private sessionExpireTime: any;
    private intervalTime: number = 3000;
    constructor(
        private window: WindowRefService,
        private logoutService: LogoutService,
        private userService: UserService,
        private appConfigService: AppConfigService
    ) {
        this.sessionExpireTime = this.appConfigService.get('sessionExpireTime');
        this.intervalTime = Math.floor(this.sessionExpireTime / DIVIDE_BY_THREE) * SECONDS_MULTIPLIER;
    }

    async run() {
        if (this.window.nativeWindow.__WEBPACK_VAR_ENV === 'test') {
            return;
        }
    
        if (this.timeoutId) {
            this.window.nativeWindow.clearInterval(this.timeoutId);
        }
        this.timeoutId = this.window.nativeWindow.setInterval(
            () => this.checkExpired(), this.intervalTime);
    }

    checkExpired() {
        if (this.userService.isAuthenticated() && this.hasExpired()) {
            this.onExpiredHandler();
        }
    }

    onExpiredHandler() {
        this.logoutService.navigate();
    }

    update() {
        this.lastUsed = Date.now();
    }

    hasExpired() {
        return isFinite(this.sessionExpireTime) && isFinite(this.lastUsed) &&
            Math.floor((Date.now() - this.lastUsed) / SECONDS_MULTIPLIER) >= this.sessionExpireTime;
    }
}
