import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';
import { ThemeVarsService } from '../themes/theme-vars.service';

@Injectable({
    providedIn: 'root',
})
export class StylesManagerService {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private appConfigService: AppConfigService,
        private themeVarsService: ThemeVarsService,
    ) {}

    /**
     * Set the stylesheet with the specified key.
     */
    setStyle(key: string, href: string): void {
        const versionParam = `${href.includes('?') ? '&' : '?'}v=${window.__WEBPACK_VAR_COMPILE_TIME}`;

        this.getLinkElementForKey(key).setAttribute('href', `${href}${versionParam}`);
    }

    setSectionStyle(sectionName: string): void {
        const themes = this.themeVarsService.getThemeNames();
        const urlParams = new URLSearchParams(window.location.search);
        const urlTheme = urlParams.get('theme') || '';
        let theme = themes.includes(urlTheme) ? urlTheme : this.appConfigService.get('theme');
        theme = theme.toLowerCase();

        const key = `${sectionName}_${theme}`;
        const href = `${sectionName}_${theme}.css`;
        this.setStyle(key, href);
    }

    /**
     * Remove the stylesheet with the specified key.
     */
    removeStyle(key: string): void {
        const existingLinkElement = this.getExistingLinkElementByKey(key);
        if (existingLinkElement) {
            this.document.head.removeChild(existingLinkElement);
        }
    }

    getLinkElementForKey(key: string): HTMLLinkElement {
        return this.getExistingLinkElementByKey(key) || this.createLinkElementWithKey(key);
    }

    getExistingLinkElementByKey(key: string): HTMLLinkElement | null {
        return this.document.head.querySelector(
            `link[rel="stylesheet"].${this.getClassNameForKey(key)}`
        );
    }

    createLinkElementWithKey(key: string): HTMLLinkElement {
        const linkEl = this.document.createElement('link');
        linkEl.setAttribute('rel', 'stylesheet');
        linkEl.classList.add(this.getClassNameForKey(key));
        this.document.head.appendChild(linkEl);
        return linkEl;
    }

    getClassNameForKey(key: string): string {
        return `style-manager-${key}`;
    }
}
