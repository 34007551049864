import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config/app-config.service';

export type AppTheme =
    | 'base'
    | 'movistar'
    | 'vivo'
    | 'O2_uk_local'
    | 'O2_germany'
    | 'O2_germany_local'
    | 'windhellas'
    | 'tigo'
    | 'ttech';
export type AppThemes = Record<AppTheme, { [k: string]: string }>;

import tailwindConfig from '../../../../tailwind.config.js';
import resolveConfig from 'tailwindcss/resolveConfig';
import type { Config } from 'tailwindcss/types/config';

@Injectable({
    providedIn: 'root',
})
export class ThemeVarsService {
    private readonly themes: AppThemes = {} as AppThemes;
    private tailwindCls: Config | null = null;

    constructor(private appConfigService: AppConfigService) {}
    /**
     * Returns the value of the given SASS variable for the current theme.
     * Use '-' in place of '_'.
     *
     * @param {*} token
     * @returns
     * @memberof ThemeVarsService
     */
    public getValue(token: string): string {
        const currentTheme: AppTheme = this.appConfigService.get('theme');
        return (
            (token &&
                this.themes[currentTheme] &&
                this.themes[currentTheme][token]) ||
            ''
        );
    }

    public getCurrentTheme(): string {
        const urlParams = new URLSearchParams(window.location.search);
        const urlTheme = urlParams.get('theme');
        return urlTheme || this.appConfigService.get('theme');
    }

    public setThemes(themes: AppThemes): void {
        Object.assign(this.themes, themes);
    }

    public getThemeNames(): string[] {
        return (this.themes && Object.keys(this.themes)) || [];
    }

    public getTailwindConfig(): Config {
        if (this.tailwindCls === null) {
            this.tailwindCls = resolveConfig(tailwindConfig);
        }
        return this.tailwindCls;
    }
    public getTailwindColor(color: string): string {
        const tailwindConfig = this.getTailwindConfig();
        const colors = tailwindConfig?.theme?.colors;
        return (
            (colors && colors[color as keyof typeof colors]) || 'transparent'
        );
    }

    public getThemeAssetsPath(): string {
        const currentTheme = this.getCurrentTheme();
        const themeMap: { [key: string]: string } = {
            base: '/src/styles/images/base/',
            movistar: '/src/styles/images/movistar/',
            vivo: '/src/styles/images/vivo/',
            O2_uk_local: '/src/styles/images/O2_uk_local/',
            O2_germany: '/src/styles/images/O2_germany/',
            O2_germany_local: '/src/styles/images/O2_germany_local/',
            windhellas: '/src/styles/images/windhellas/',
            tigo: '/src/styles/images/tigo/',
            ttech: '/src/styles/images/ttech/',
        };
        return themeMap[currentTheme];
    }
}
