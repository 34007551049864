import {
    UrlLanguageDetector,
    LocalStorageLanguageDetector,
    CookieLanguageDetector,
    BrowserLanguageDetector,
} from './language-detectors';

const detectors = [
    UrlLanguageDetector,
    LocalStorageLanguageDetector,
    CookieLanguageDetector,
    BrowserLanguageDetector,
];

export function cultureInfoResolver(supported: Array<any>, fallback: any, langCode?: string) {
    let culture, originalCulture;

    const processLang = function processLang(lang: string | undefined): boolean {
        if (lang) {
            // Normalize the lang
            lang = lang.replace('_', '-').toLowerCase();
            originalCulture = lang;

            // If an exact match cannot be found
            if (supported.includes(lang)) {
                culture = lang;
                return true;
            }

            // Check just the language part
            const langSubstr = lang.substr(0, 2);
            lang = supported.find(locale => ((langSubstr === locale.substr(0, 2)) && (culture = locale)));

            return !!lang;
        } else {
            return !!lang;
        }
    };

    if ((langCode !== undefined)) {
        processLang(langCode);
    } else {
        detectors.find(decFn => processLang(decFn()));
    }

    const cultureToBeUsed = culture || fallback || supported[0];
    const language = cultureToBeUsed.split('-')[0];
    const region = cultureToBeUsed.split('-')[1];

    return {
        originalCulture: originalCulture,
        fallback: fallback,
        culture: cultureToBeUsed,
        language: language,
        region: region,
        languageTag: `${language}-${region.toUpperCase()}`,
    };
}
