import { I18nService } from '../i18n/i18n.service';
import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { BehaviorSubject } from 'rxjs';
import { isPlainObject } from 'lodash-es';

export type TranslationLiterals = Record<string, string>;
export type Translate = (key: string, opArgs?: string[]) => string;

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private readonly subject: BehaviorSubject<TranslationLiterals> = new BehaviorSubject({});
    public readonly updates$ = this.subject.asObservable();
    /**
     * HTML codes and its corresponding translations values
     *
     * @memberof TranslationService
     */
    private entities: any = {
        '&amp;': '&',
        '&gt;': '>',
        '&lt;': '<',
        '&quot;': '"',
    };

    /**
     * Reference to a regular expression used to find HTML codes within a string.
     *
     * @memberof TranslationService
     */
    private regex: any = null;
    private registerInKermit: Function;
    private moduleBasePath = 'packages/m2m-portal/mc/res/locale';
    private literals: TranslationLiterals = {};

    constructor(
        private window: WindowRefService,
        private i18nService: I18nService,
    ) {
        // preset a regular expression using current html code entities.
        this.regex = this.buildHTMLCodeRegex(this.entities);

        // Preset a function that resolves a global access to translation tool, by mean of window.__.
        // This factory function solves a context issue when invoking 'registerInKermit' in the angular module run block.
        // Please remove when access to this translation service is done using dependency injection completely.
        this.registerInKermit = this.registerInKermitFactory();
    }

    /**
     * Helper function used as a replacer function to substitute HTML codes.
     *
     * @param {*} match
     * @param {*} capture
     * @returns
     * @memberof TranslationService
     */
    _htmlDecodeReplacer(match: any, capture: string): any {
        if (capture in this.entities) {
            return this.entities[capture];
        } else {
            return String.fromCharCode(parseInt(capture.substr(2), 10));
        }
    }

    /**
     * Looks for HTML codes in the input literal and replace by the corresponding character, according
     * to the replacer function.
     *
     * @param {*} value
     * @returns
     * @memberof TranslationService
     */
    _htmlDecode(value: string): string {
        return (!value) ? value : String(value).replace(this.regex, this._htmlDecodeReplacer);
    }

    /**
     * Find matches in the input message, according to the token defined in regex and substitute
     * each occurrence by each of the arguments passed in, that are captured by the argument 'rest'
     *
     * @example
     *   var message = 'La suma de {0} y {1} es igual a 8';
     *   var value1 = 3;
     *   var value2 = 5;
     *
     *   _format(message, value1, value2); // 'La suma de 3 y 5 es igual a 8'
     *
     * @param {*} message string literal to be analized
     * @param {*} rest Array of arguments passed in that will be replaced.
     * @returns
     * @memberof TranslationService
     */
    _format(message: string, ...rest: any): string {
        const formatRe = /\{(\d+)\}/g;
        return message.replace(formatRe, (m, i) => rest[i]);
    }

    /**
     * Helper i18n (like "gettext" or ZF) to ease i18n translations.
     * Interpolations works by using placeholders in the literal with the
     * format "{N}" where N is the index of the interpolated variables
     * starting at 0.
     *
     * Example:
     *   __('Hello {1}, your email is {0}', user.email, user.name);
     *
     * @param {*} literal Key for a given translation
     * @param {*} restOfArgs
     * @returns {String} Translated string.
     * @memberof TranslationService
     */
    getTranslation(literal: string, ...restOfArgs: any): string {
        let trans = this.literals[literal];

        if (!trans) {
            return this._htmlDecode(literal);
        }

        trans = this._htmlDecode(trans);

        if (restOfArgs.length) {
            trans = this._format(trans, ...restOfArgs);
        }

        return trans;
    }

    /**
     * Helper function to export some method of this service to the global context.
     * TODO: Please remove when Kermit use is completely left behind and inject the service instead.
     *
     * @memberof TranslationService
     */
    registerInKermitFactory() {
        const fn = this.getTranslation.bind(this);
        const window = this.window.nativeWindow;
        return function () {
            window.Kermit = window.Kermit || {};
            window.Kermit.gettext = window.__ = fn;
        };
    }

    /**
     * Return a new regular expression using an array of keys as arguments to build it.
     *
     * @param {*} entities Object of html codes with their corresponding replacements.
     * @returns
     * @memberof TranslationService
     */
    buildHTMLCodeRegex(entities: {}): RegExp {
        const keys = Object.keys(entities);
        return new RegExp('(' + keys.join('|') + '|&#[0-9]{1,5};' + ')', 'g');
    }


    /**
     * Fetch a translation resource from server.
     *
     * @param {*} url
     * @returns
     * @memberof TranslationService
     */
    fetchJson(url: any): Promise<void> {
        url = Array.isArray(url) ? url : [url];
        const _fetch = (url: any) => window.fetch(url + `?v=${window.__WEBPACK_VAR_COMPILE_TIME}`).then(r => r.json());

        return Promise
            .all(url.map((res: any) => _fetch(res)))
            .then((r) => this.loadTranslation(r))
            .catch((error) => Promise.reject(error));
    }

    loadModuleTranslation(moduleName: string): Promise<any> {
        return this.fetchJson([
            `${this.moduleBasePath}/${moduleName}/${this.i18nService.getCulture()}.json`
        ]);
    }

    loadTranslation(data: TranslationLiterals[] | TranslationLiterals): void {
        if (!Array.isArray(data) && !isPlainObject(data)) {
            return;
        }

        let _data = Array.isArray(data) ? data : [data];
        this.literals = _data.reduce(
            (acc, cur) => ({ ...acc, ...cur }), this.literals);
    }

    has(transKey: string): boolean {
        return !!this.literals[transKey];
    }

    /**
     * Keeps backwards compatibility where M2M.i18n.exists is used.
     *
     * @param {*} transKey
     * @returns
     * @memberof TranslationService
     */
    exists(transKey: string): boolean {
        return this.has(transKey);
    }

    getTranslationFrom(translations: any = {}, DEFAULT = 'en-US'): string {
        const curLang = this.i18nService.getLanguage();
        const curLangTag = this.i18nService.getLanguageTag();
        return translations[curLang] || translations[curLangTag] || translations[DEFAULT] || '';
    }
}
