import { Injectable } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { AuthAuthorizationHeaders } from '../auth/auth.service';
import { AppConfigService } from '../app-config/app-config.service';
import { WindowRefService } from '../window-ref/window-ref.service';

const AUTHORIZATION_TOKEN = 'M2MToken';
const AUTHORIZATION_USER_PREFIX = 'M2MUser';

@Injectable({
    providedIn: 'root'
})
export class HttpCommonHeadersService {
    protected _headers: any = null;

    shouldObfuscate = [
        AUTHORIZATION_USER_PREFIX,
    ];

    $http: any;

    constructor(
        private upgrade: UpgradeModule,
        private appConfigService: AppConfigService,
        private windowRefService: WindowRefService,
    ) { }

    /**
     * Clears the common headers.
     */
    public clearHeaders() {
        this._headers = null;
        this.upgrade.$injector.get('$http').defaults.headers!.common = null;
    }

    /**
     * Returns the common headers.
     * @returns A record of the common headers.
     */
    public getCommonHeaders() {
        return this._headers;
    }

    public setCommonHeaders(authToken?: string, csrfToken?: string) {
        const headers = this.headerWithAuthorization(authToken, csrfToken)

        if (headers === null) {
            return this.clearHeaders();
        }

        this._headers = headers;
        this.upgrade.$injector.get('$http').defaults.headers!.common = headers;
        return this._headers;
    }

    public headerWithAuthorization(authToken?: string, csrfToken?: string): AuthAuthorizationHeaders | null {
        if (authToken !== null && authToken !== undefined && csrfToken !== null && csrfToken !== undefined) {
            const authorization = this.normalizeAuthorization(AUTHORIZATION_TOKEN, authToken);
            return {
                'Authorization': authorization,
                'X-Csrf-Token': csrfToken,
                'X-M2M-NoAuthBasic': "1",
                'Content-Type': 'application/json',
            };
        }
        return null;
    }

    private normalizeAuthorization(prefix: string, credential: string | null): string {
        const _credential = this.shouldObfuscateCredential(prefix)
            ? this.windowRefService.nativeWindow.btoa(credential!)
            : credential;
        return [prefix, _credential].join(' ');
    }

    private shouldObfuscateCredential(prefix: string): boolean {
        return this.appConfigService.get('passObfuscationEnabled') && this.shouldObfuscate.includes(prefix);
    }
}
