import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '/src/app/services/translation/translation.service';

/**
 * Transforms a given translation literal into its translated string.
 *
 * @param translationLiteral - The key representing the translation entry.
 * @param optionalArgs - Optional parameters can be passed for dynamic values in translations.
 * @returns The translated string corresponding to the translation literal.
 */
@Pipe({
    name: 'translate',
    standalone: true,
})
export class TranslatePipe implements PipeTransform {
    constructor(public translationService: TranslationService) {}

    transform(translationLiteral: string, ...optionalArgs: any[]): string {
        return this.translationService.getTranslation(
            translationLiteral,
            ...optionalArgs
        );
    }
}
