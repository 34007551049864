import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { CheckExpiredService } from '../services/check-expired/check-expired.service';

const XCSRF = 'X-CSRF-Token';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private checkExpiredService: CheckExpiredService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap({ next: (event) => this.responseHandler(req, event) }))
    }

    responseHandler(request: HttpRequest<any>, event: HttpEvent<any>) {
        if (event instanceof HttpResponse) {
            // get updated XCSRF token from response and update on user service.
            if (event.headers.get(XCSRF) && request.url.startsWith('/api')) {
                this.userService.setCsrfToken(event.headers.get(XCSRF));
            }
        }
        this.checkExpiredService.update();
        return event;
    }
}
