import { getTextWidth } from  './html/get-text-width';

/**
 * Calculate effective font size and return size in px.
 *  Args:
 *  data:  can be a string or an array of strings.
 *  parentEl: is the element's parent node.
 *  maxSize: maximun font size reached
 *  marginPercentage: percentage of the parent's width available to accomodate the texts.
 *
 *  Return the number of pixels without unit.
 *
 * @export
 * @param {*} parentEl D3Element | HTMLElement
 * @param {*} data string | string[]
 * @returns
 */
const fontSize = 10;
const baseFont = `bold ${fontSize}px Arial`;

export function svgFontResizer(parentEl: HTMLElement, data: any, maxSize = 14, marginPercentage = .1) {
    const _data = !Array.isArray(data) ? [data] : data;
    const parentWidth = parentEl.getBoundingClientRect().width;
    const maxTextWidth = Math.max.apply(null, _data.map(t => getTextWidth(t, baseFont)));
    const size = Math.floor(((parentWidth * (1 - marginPercentage) * fontSize) / maxTextWidth));
    return Math.min(size, maxSize) + 'px';
}
