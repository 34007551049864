import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { KiteIdDirective } from '../../../directives/m2mid/kite-m2mid.directive';

export const DEFAULT_DIALOG_WIDTH = '600px';

@Component({
    selector: 'kite-dialog',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        TranslatePipe,
        MatIconModule,
        KiteIdDirective,
    ],
    templateUrl: './dialog.component.html',
    styles: `
        :host {
            padding: 10px;
        }
    `,
    styleUrl: './dialog.component.scss',
})
export class DialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            description?: string;
            closeText?: string;
            submitText?: string;
            m2mid?: string;
        } & { [key: string]: any }
    ) {}
}
