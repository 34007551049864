import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';
import { ICustomWindow, WindowRefService } from '../window-ref/window-ref.service';

export interface UserCredential {
    userName: string;
    impersonatingOrgName?: string;
}

export interface OrganizationData {
    id: string;
    name: string;
    type: string;
    providerId: string;
}

const KDAF_DIM = 1;
const ORG_ID_DIM = 2;
const ORG_NAME_DIM = 3;
const ORG_TYPE_DIM = 4;
const HOSTNAME_DIM = 5;
const PROVIDER_ID_DIM = 6;
const LOGOUT_ROUTE = '/logout';
const FROM_URL = 'index';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsToolService {
    private readonly analyticsEnabled: boolean;
    private readonly nativeWindow: ICustomWindow;
    private fromUrl = FROM_URL;

    get KDAF_DIM (): number {
        return KDAF_DIM;
    }

    get ORG_ID_DIM (): number {
        return ORG_ID_DIM;
    }

    get ORG_NAME_DIM (): number {
        return ORG_NAME_DIM;
    }

    get ORG_TYPE_DIM (): number {
        return ORG_TYPE_DIM;
    }

    get HOSTNAME_DIM (): number {
        return HOSTNAME_DIM;
    }

    get PROVIDER_ID_DIM (): number {
        return PROVIDER_ID_DIM;
    }

    get LOGOUT_ROUTE (): string {
        return LOGOUT_ROUTE;
    }

    constructor (
        private appConfigService: AppConfigService, 
        private windowRefService: WindowRefService) {
        this.analyticsEnabled = !!this.appConfigService.get('analyticsEnabled');
        this.nativeWindow = this.windowRefService.nativeWindow;
    }    

    public isEnabled(): boolean {
        return this.analyticsEnabled;
    }

    public registerNavigation(toUrl: string): void {
        if (this.analyticsEnabled) {
            if (['', 'index', this.fromUrl].includes(toUrl)) {
                return;
            }
            
            this.registerPageView(toUrl);
            this.fromUrl = toUrl;
        }
    }

    public registerEvent(category: any, eventName: any, eventValue: any): void {
        if (this.analyticsEnabled) {
            this.nativeWindow._paq.push(['trackEvent', category, eventName, eventValue]);
        }
    }

    public registerLogin(
        userCredential: UserCredential, 
        organizationData: OrganizationData): void {
        if (this.analyticsEnabled) {
            this.nativeWindow._paq.push(['setCustomDimension', this.ORG_ID_DIM, organizationData.id]);
            this.nativeWindow._paq.push(['setCustomDimension', this.ORG_NAME_DIM, organizationData.name]);
            this.nativeWindow._paq.push(['setCustomDimension', this.ORG_TYPE_DIM, organizationData.type]);
            this.nativeWindow._paq.push(['setCustomDimension', this.HOSTNAME_DIM, this.nativeWindow.location.hostname]);
            this.nativeWindow._paq.push(['setCustomDimension', this.PROVIDER_ID_DIM, organizationData.providerId]);

            if (userCredential.userName.toLowerCase().includes('kdaf')
                || organizationData.name.toLowerCase().includes('kdaf')) {
                this.nativeWindow._paq.push(['setCustomDimension', this.KDAF_DIM, 'true']);
            }
        }
    }

    public registerImpersonation(userCredential: UserCredential): void {
        if (this.analyticsEnabled) {
            this.registerNavigation(`/impersonate/${userCredential.impersonatingOrgName}`);
        }
    }

    public registerLogout(): void {
        if (this.analyticsEnabled) {
            this.registerPageView(this.LOGOUT_ROUTE);
            this.nativeWindow._paq.push(['appendToTrackingUrl', 'new_visit=1']);
            this.nativeWindow._paq.push(['appendToTrackingUrl', '']);
        }
    }

    private registerPageView(url: string): void {
        this.nativeWindow._paq.push(['setReferrerUrl', this.fromUrl]);
        this.nativeWindow._paq.push(['setCustomUrl', url]);
        this.nativeWindow._paq.push(['setDocumentTitle', url]);
        this.nativeWindow._paq.push(['deleteCustomVariables', 'page']);
        this.nativeWindow._paq.push(['trackPageView']);
    }
}