import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ResponseInterceptor } from './response-interceptor';
import { ResponseError401Interceptor } from './response-error-401-interceptor';
import { ResponseError403Interceptor } from './response-error-403-interceptor';
import { ResponseErrorInterceptor } from './response-error-interceptor';
import { ResponseLowerVersionInterceptor } from './response-lower-version-interceptor';
import { AuthInterceptor } from './auth-interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, 
    { provide: HTTP_INTERCEPTORS, useClass: ResponseLowerVersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseError403Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseError401Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
];
