import { Injectable } from '@angular/core';
import { uuidv4 } from '@shared/utils';
import BROADCAST_MESSAGES from './broadcast-messages';

type TopicArgs = {
    callback: Function;
    scope?: any;
    once?: boolean;
};
type Topics = {
    [topic: string]: Record<string, TopicArgs>;
};
/**
 *  Generic implementation of the publish/subscribe pattern as an angular service.
 *
 * @export
 * @class BroadcasterService
 */
@Injectable({
    providedIn: 'root',
})
export class BroadcastService {
    private readonly _topics: Topics = {};

    get messages(): Record<string, string> {
        return BROADCAST_MESSAGES;
    };

    /**
     * Subscribes a new topic in this event emitter service.
     * where topic is a custom name used to identify the event and
     * options can be whether a function callback to be invoked when topic is published.
     * Returns a random ID that identify the registration. It can later be used to unsubscribe the topic.
     *
     * Option could be an object like this:
     * {
     *  callback: function() {},
     *  scope: null,
     *  once: false
     * }
     *
     * @param {any} topic
     * @param {any} options
     * @returns
     * @memberof BroadcasterService
     */
    subscribe(topic: string, options: TopicArgs | Function ): string | boolean {
        if (typeof options === 'function') {
            options = { callback: options };
        } else if (typeof options.callback === 'function'){
            options['callback'] = options.callback;
            options['scope'] = options.scope;
            options['once'] = options.once;
        } else {
            return false;
        }

        if (!this._topics.hasOwnProperty(topic)) {
            this._topics[topic] = {};
        }

        const id = uuidv4();
        this._topics[topic][id] = options;

        return id;
    }

    /**
     * Subscribes a topic that will be automatically unsuscribe once a publish event
     * is emitted.
     *
     * @param {any} topic
     * @param {any} callback
     * @memberof BroadcasterService
     */
    subscribeOnce(topic: string, callback: Function): string | boolean {
        return this.subscribe(topic, {
            once: true,
            callback: callback,
        });
    }

    /**
     * Publishes topic events, which will derive in the execution of the associated
     * callbacks to those topics.
     *
     * @param {any} topic
     * @returns
     * @memberof BroadcasterService
     */
    publish(topic: string, ...args: any[]): boolean {
        if (this._topics.hasOwnProperty(topic)) {
            let indexes = [];

            for (let id in this._topics[topic]) {
                if (this._topics[topic].hasOwnProperty(id)) {
                    const event = this._topics[topic][id];
                    try {
                        event.callback.apply((event.scope || null), args);
                    } catch (ex) {
                        console.error(ex);
                    }

                    if (event.once) {
                        indexes.push(id);
                    }
                }
            }

            if (indexes.length) {
                indexes.forEach(this.unsubscribe.bind(this));
            }

            return true;
        }
        return false;
    }

    /**
     * Unregister a single callback registered under a topic, using the handle id that returned when it that
     * callback was subscribed.
     *
     * @param {any} id
     * @returns
     * @memberof BroadcasterService
     */
    unsubscribe(id: any): boolean {
        for (let t in this._topics) {
            if (this._topics[t][id]) {
                delete this._topics[t][id];
                if (!Object.keys(this._topics[t]).length) {
                    delete this._topics[t];
                }
                return true;
            }
        }
        return false;
    }
}
