/**
 * Round function to accurately round with decimals.
 * See http://www.jacklmoore.com/notes/rounding-in-javascript/
 * @export
 * @param {*} value
 * @param {*} decimals
 * @returns
 */
export function roundDecimals(value: number, decimals: number): number {
    const _v = Math.round(Number(`${value}e${decimals}`));
    return Number(`${_v}e-${decimals}`);
}
