
/**
 * Convert an html string into a document fragment.
 *
 * @export
 * @param {*} html
 * @return {*}
 */
export function parseHTML(html: string): HTMLElement {
    const template = document.createElement('template');
    template.innerHTML = html;
    return <unknown>(template.content) as HTMLElement;
}
