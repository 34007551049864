import { isString, isNumber } from 'lodash-es';
import { TranslationService } from '../translation/translation.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LiteErrorService {

    public constant = {
        CODE: 'Error.code.',
    };
    public translate: any;

    constructor(private translationService: TranslationService) {
        this.translate = translationService.getTranslation.bind(translationService);
    }

    public getMessage(errorData: any): string {
        let result = '';
        if (errorData.error) {
            result = this.getMessageFromCode(errorData.error);
        } else if ((errorData.exceptionId && errorData.exceptionId.toString())) {
            result = errorData.exceptionText;
        }
        return result;
    }

    /**
     * Gets the error code from a response.
     * Returns the error code in KABCDEE format, or null if not found.
     *
     * @param {*} response
     * @returns
     * @memberof LiteErrorService
     */
    public getErrorCodeFromResponse(response: any): string | null {
        let result = null;
        const error = response?.error;
        if (error && this.isValidErrorCode(error)) {
            result = this.code2String(error);
        }
        return result;
    }

    /**
     * error data format: {"firstName": {"isEmpty":"Value is required and can't be empty"}}
     * returns {"firstName": ["isEmpty"]}
     *
     * @param {*} errorData BE Response.
     * @returns
     * @memberof LiteErrorService
     */
    public getParameters(errorData: any) {
        const params = {};

        getParamsKeyRecursively(params, [], errorData.validationErrors);

        return params;

        function getParamsKeyRecursively(result: any, keyParts: any[], paramObj = {}) {
            const key = keyParts.join('.');

            Object.entries(paramObj).forEach(([k, v]) => {
                if (isString(v)) {
                    //end node -> join keys with dot and compose the result of this branch
                    if (!result[key]) {
                        result[key] = [];
                    }
                    result[key].push(k);
                } else {
                    // accumulate key parts of the branch
                    keyParts.push(k);
                    getParamsKeyRecursively(result, keyParts, v!);
                    keyParts.pop();
                }
            });
        }
    }

    /**
     * Take a padded string as input of a certain length,
     * and returns the corresponding error literal, which is translatable, if exists.
     * Returns null otherwise.
     *
     * @param {*} code
     * @returns
     * @memberof LiteErrorService
     */
    public getErrorLiteral(code: string): string | null {
        let result = null;
        let _code;

        if (code.length === 7) {
            _code = code.slice(0, 5);
        } else if (code.length === 8) {
            _code = code.substring(0, 3) + '.' + code.substring(4, 8);
        }

        const literal = this.constant.CODE + _code;

        if (this.isLiteralDefined(literal)) {
            return literal;
        }

        return result;
    }

    public isLiteralDefined(key: any): boolean {
        return this.translationService.exists(key);
    }

    /**
     * Converts error code from number to string. Makes a zero padding up to 7 digits
     *
     * @param {*} code
     * @returns
     * @memberof LiteErrorService
     */
    public code2String(code:any): string {
        if (isNumber(code)) {
            // Zero padding. Error codes must have 7 digits
            code = ('000000' + code).slice(-7);
        }
        return code;
    }

    /**
     * Return the corresponding translated message from an error code.
     * @param {*} code
     * @returns
     * @memberof LiteErrorService
     */
    public getMessageFromCode(code: number | string): string {
        let message = null;

        if (code) {
            let _code = this.code2String(code);
            //check code format
            if (this.isValidErrorCode(_code)) {
                if (_code.length === 7) {
                    _code = _code.slice(0, 5);
                } else if (_code.length === 8) {
                    _code = _code.substring(0, 3) + '.' + _code.substring(4, 8);
                }

                const literal = this.constant.CODE + _code;
                if (this.isLiteralDefined(literal)) {
                    message = this.translate(literal);
                }
            }
        }
        return message;
    }

    /**
     * Determines whether an error code is a valid code. Returns true if valid and
     * false otherwise.
     *
     *
     * @param {*} code
     * @returns
     * @memberof LiteErrorService
     */
    public isValidErrorCode(code: number | string): boolean {
        let result = true;

        if (code == null) {
            return false;
        }

        if (!isString(code)) {
            code = code.toString();
        }

        if (code.length !== 7 || Number.isNaN(parseInt(code, 10))) {
            result = false;
        }

        if (code.length === 8 &&
            code.substring(0, 4) === 'svc_' &&
            !Number.isNaN(parseInt(code.substring(4, 8), 10))) {
            result = true;
        }

        return result;
    }
}
