const BROADCAST_SERVICE_MESSAGES: Record<string, string> = {
    SPINNER_HIDE: 'spinner:hide',
    SPINNER_SHOW: 'spinner:show',
    SPINNER_ADD_CLS: 'spinner:add:class',

    USER_LOGGED_IN: 'user:logged:in',
    BEFORE_LOGOUT: 'before:logout',

    HEADER_INFO_PROTOCOL: 'header-info-component::protocol',

    QUICK_SEARCH_APPLIED: 'searchapplied',
    PACKAGE_VIEW_TOGGLE: 'packageView:toggle',

    USER_PRESETS_SAVE: 'user-presets::save',

    GLOBAL_NOTIFICATION_SHOW: 'notification:show',
    GLOBAL_NOTIFICATION_HIDE: 'notification:hide',

    ASSERTS_ADDED: 'asserts:added',
    AUTHENTICATION_ENDED: 'authentication:ended',
    SIM_SIGNAL_QUALITY: 'sim_signal_quality',
    SIM_RESET_OPERATION: 'sim_reset_operation',
    COLLECT_DATA_OPERATION: 'collect_data_operation',
    DEVICE_CAPABILITIES_OPERATION: 'device_capabilities_operation',
    IMEI_RELEVANT_CHANGE: 'imei_relevant_change',
    // signals when kermit finished loading all extjs assets and extjs launch.
    EXTJS_LOAD_COMPLETED: 'EXTJS_LOAD_COMPLETED',

    KITE_MENU_NAVIGATE: 'KITE_MENU_NAVIGATE',
    ANOMALY_SUBSCRIPTIONS: 'ANOMALY_SUBSCRIPTIONS',
};

export default Object.freeze(BROADCAST_SERVICE_MESSAGES);
