import { deNormalizeCredential, deNormalizeAuthorization } from '../services/auth/utils';
import { isPasswordExpiredError, isRequiresTwoFactorError, isStatus403Error } from '../services/http-interceptors/utils';
import { BroadcastService } from '../services/broadcaster/broadcast.service';
import { LiteErrorService } from '../services/error/lite-error.service';
import { UpgradeModule } from '@angular/upgrade/static';
import { WindowRefService } from '../services/window-ref/window-ref.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { HttpServiceEvents } from '../services/http/http-events';

@Injectable()
export class ResponseError403Interceptor implements HttpInterceptor {
    constructor(
        private broadcastService: BroadcastService,
        private liteErrorService: LiteErrorService,
        private upgrade: UpgradeModule,
        private window: WindowRefService
    ) {}

    passExpirationErrorHandler(requestHeaders: HttpHeaders, responseHeaders: HttpHeaders): void {
        const $timeout = this.upgrade.$injector.get('$timeout');
        const $location = this.upgrade.$injector.get('$location');

        // Extract userName from 'Authorization' request header
        const userAuth = deNormalizeAuthorization(requestHeaders.get('Authorization') ?? '');
        const credential = userAuth.credential && deNormalizeCredential(userAuth.credential);

        if (credential && credential.identity) {
            const usrCredential = deNormalizeCredential(decodeURIComponent(this.window.nativeWindow.atob((credential.identity))));
            const usr = usrCredential && usrCredential.identity;

            if (usr) {
                const token = responseHeaders.get('x-m2m-passwordexpiredtoken');
                const lang = responseHeaders.get('x-m2m-userlanguage');
                $timeout(() => $location.url(`/expiredpassword?userName=${usr}&token=${token}&lang=${lang}`));
            }
        }
    }

    requireTwoFactorErrorHandler(headers: HttpHeaders): void {
        const $timeout = this.upgrade.$injector.get('$timeout');
        const $location = this.upgrade.$injector.get('$location');
        //Two factor login is mandatory for this user
        $timeout(() => $location.url(`/twofactorlogin?token=${headers.get('x-m2m-authtoken')}`));
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap({ error: (event) => this.errorHandler(req, event) }));
    }

    errorHandler(request: HttpRequest<any>, event: HttpErrorResponse) {
        if (event instanceof HttpErrorResponse) {
            const errorCode = this.liteErrorService.getErrorCodeFromResponse(event);
            if (isStatus403Error(event.status) && errorCode) {
                const requestHeaders = request.headers;
                const responseHeaders = event.headers;
                const requireTwoFactor = isRequiresTwoFactorError(errorCode);

                this.broadcastService.publish(HttpServiceEvents.LAST_REQUEST_DATA_EVENT, {
                    url: event.url,
                    data: request.body || {},
                    path: this.upgrade.$injector.get('$location').path(),
                    xcsrf: responseHeaders.get('x-csrf-token'),
                    requireTwoFactor,
                });

                if (requireTwoFactor) {
                    // Two factor login is mandatory for this user
                    this.requireTwoFactorErrorHandler(responseHeaders);
                } else if (isPasswordExpiredError(errorCode)) {
                    // Extract userName from 'Authorization' request header
                    this.passExpirationErrorHandler(requestHeaders, responseHeaders);
                } else {
                    // SONAR
                }
            }
        }
        return event;
    }
}
