/**
 * Checks if an element is parent of a child element.
 *
 * @export
 * @param {*} child
 * @param {*} parent
 * @returns
 */
export function isParent(child: Node, parent: Node): boolean {
    let isParentNode = false;

    if (parent && child) {
      let node = child;

      while (!isParentNode && node) {
        if (node === parent) {
          isParentNode = true;
        } else {
          node = node.parentNode as Node;
        }
      }
    }
    return isParentNode;
}
