module.exports = {
    important: true,
    corePlugins: {
        preflight: false,
    },
    prefix: 'tw-',
    content: [
        './src/**/*.html',
        './src/**/*.js',
        './src/**/*.ts',
        './packages/m2m-portal/**/*js',
    ],
    theme: {
        extend: {
            keyframes: {
                fadeinkeyframe: {
                    '0%': { opacity: '0' },
                    '100%': { opacity: '1' },
                },
            },
            animation: {
                'kite-fadein': 'fadeinkeyframe 500ms ease-in-out',
            },
            fontFamily: {
                kite: ['var(--kite-font-family)'],
                'kite-bold': ['var(--kite-font-telefonica-sans-bold)'],
                'kite-light': ['var(--kite-font-telefonica-sans-light)'],
                'kite-regular': ['var(--kite-font-telefonica-sans-regular)'],
                'kite-medium': ['var(--kite-font-telefonica-sans-medium)'],
                'kite-demiBold': ['var(--kite-font-telefonica-sans-demiBold)'],
            },
            colors: {
                'primary-00': 'var(--kite-primary-00-color)',
                'primary-01': 'var(--kite-primary-01-color)',
                'primary-05': 'var(--kite-primary-05-color)',
                'primary-10': 'var(--kite-primary-10-color)',
                'primary-20': 'var(--kite-primary-20-color)',
                'primary-30': 'var(--kite-primary-30-color)',
                'primary-40': 'var(--kite-primary-40-color)',
                'primary-50': 'var(--kite-primary-50-color)',
                'primary-60': 'var(--kite-primary-60-color)',
                'primary-70': 'var(--kite-primary-70-color)',
                'primary-80': 'var(--kite-primary-80-color)',
                'primary-90': 'var(--kite-primary-90-color)',
                'primary-90-alpha05': 'var(--kite-primary-90-alpha05-color)',

                'grays-05': 'var(--kite-grays-05-color)',
                'grays-10': 'var(--kite-grays-10-color)',
                'grays-20': 'var(--kite-grays-20-color)',
                'grays-30': 'var(--kite-grays-30-color)',
                'grays-30-alpha70': 'var(--kite-grays-30-alpha70-color)',
                'grays-40': 'var(--kite-grays-40-color)',
                'grays-50': 'var(--kite-grays-50-color)',
                'grays-60': 'var(--kite-grays-60-color)',
                'grays-70': 'var(--kite-grays-70-color)',
                'grays-80': 'var(--kite-grays-80-color)',
                'grays-90': 'var(--kite-grays-90-color)',

                'green-05': 'var(--kite-green-05-color)',
                'green-10': 'var(--kite-green-10-color)',
                'green-20': 'var(--kite-green-20-color)',
                'green-30': 'var(--kite-green-30-color)',
                'green-40': 'var(--kite-green-40-color)',
                'green-50': 'var(--kite-green-50-color)',
                'green-60': 'var(--kite-green-60-color)',
                'green-70': 'var(--kite-green-70-color)',
                'green-80': 'var(--kite-green-80-color)',
                'green-90': 'var(--kite-green-90-color)',

                'purple-05': 'var(--kite-purple-05-color)',
                'purple-10': 'var(--kite-purple-10-color)',
                'purple-20': 'var(--kite-purple-20-color)',
                'purple-30': 'var(--kite-purple-30-color)',
                'purple-40': 'var(--kite-purple-40-color)',
                'purple-50': 'var(--kite-purple-50-color)',
                'purple-60': 'var(--kite-purple-60-color)',
                'purple-70': 'var(--kite-purple-70-color)',
                'purple-80': 'var(--kite-purple-80-color)',
                'purple-90': 'var(--kite-purple-90-color)',

                'magenta-05': 'var(--kite-magenta-05-color)',
                'magenta-10': 'var(--kite-magenta-10-color)',
                'magenta-20': 'var(--kite-magenta-20-color)',
                'magenta-30': 'var(--kite-magenta-30-color)',
                'magenta-40': 'var(--kite-magenta-40-color)',
                'magenta-50': 'var(--kite-magenta-50-color)',
                'magenta-60': 'var(--kite-magenta-60-color)',
                'magenta-70': 'var(--kite-magenta-70-color)',
                'magenta-80': 'var(--kite-magenta-80-color)',
                'magenta-90': 'var(--kite-magenta-90-color)',

                'red-05': 'var(--kite-red-05-color)',
                'red-10': 'var(--kite-red-10-color)',
                'red-20': 'var(--kite-red-20-color)',
                'red-30': 'var(--kite-red-30-color)',
                'red-40': 'var(--kite-red-40-color)',
                'red-50': 'var(--kite-red-50-color)',
                'red-60': 'var(--kite-red-60-color)',
                'red-70': 'var(--kite-red-70-color)',
                'red-80': 'var(--kite-red-80-color)',
                'red-90': 'var(--kite-red-90-color)',
                'red-50-alpha40': 'var(--kite-red-50-alpha40-color)',

                'orange-05': 'var(--kite-orange-05-color)',
                'orange-10': 'var(--kite-orange-10-color)',
                'orange-20': 'var(--kite-orange-20-color)',
                'orange-30': 'var(--kite-orange-30-color)',
                'orange-40': 'var(--kite-orange-40-color)',
                'orange-50': 'var(--kite-orange-50-color)',
                'orange-60': 'var(--kite-orange-60-color)',
                'orange-70': 'var(--kite-orange-70-color)',
                'orange-80': 'var(--kite-orange-80-color)',
                'orange-90': 'var(--kite-orange-90-color)',
                'orange-30-alpha40': 'var(--kite-orange-30-alpha40-color)',

                'yellow-05': 'var(--kite-yellow-05-color)',
                'yellow-10': 'var(--kite-yellow-10-color)',
                'yellow-20': 'var(--kite-yellow-20-color)',
                'yellow-30': 'var(--kite-yellow-30-color)',
                'yellow-40': 'var(--kite-yellow-40-color)',
                'yellow-50': 'var(--kite-yellow-50-color)',
                'yellow-60': 'var(--kite-yellow-60-color)',
                'yellow-70': 'var(--kite-yellow-70-color)',
                'yellow-80': 'var(--kite-yellow-80-color)',
                'yellow-90': 'var(--kite-yellow-90-color)',
                'yellow-20-alpha40': 'var(--kite-yellow-20-alpha40-color)',

                'blue-05': 'var(--kite-blue-05-color)',
                'blue-10': 'var(--kite-blue-10-color)',
                'blue-20': 'var(--kite-blue-20-color)',
                'blue-30': 'var(--kite-blue-30-color)',
                'blue-40': 'var(--kite-blue-40-color)',
                'blue-50': 'var(--kite-blue-50-color)',
                'blue-60': 'var(--kite-blue-60-color)',
                'blue-70': 'var(--kite-blue-70-color)',
                'blue-80': 'var(--kite-blue-80-color)',
                'blue-90': 'var(--kite-blue-90-color)',
            },
            boxShadow: {
                'kite-xs': '0 -4px 13px 0 var(--kite-grays-10-color)',
            },
        },
    },
    plugins: [],
};
