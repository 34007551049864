import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PreloadSelectedModulesStrategy } from './preload-selected-modules.strategy';

const ALL_ROUTES: Routes = [];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(ALL_ROUTES, {
            useHash: true,
            enableTracing: false,
            preloadingStrategy: PreloadSelectedModulesStrategy,
        }),
    ],
    exports: [RouterModule],
    providers: [],
    bootstrap: [],
})
export class AppRoutingModule { }
