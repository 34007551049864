import { HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    DoBootstrap,
    InjectionToken,
    NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { bootstrap } from '../bootstrap.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { AppConfigService } from './services/app-config/app-config.service';
import { cultureInfoResolver } from './services/i18n/culture-info-resolver';
import { I18nService } from './services/i18n/i18n.service';
import { MenuRouteEventHelper } from './services/menu/menu-route-event-helper.service';
import { OneTrustService } from './services/one-trust/one-trust.service';
import { RecaptchaService } from './services/recaptcha/recaptcha.service';
import {
    AppThemes,
    ThemeVarsService,
} from './services/themes/theme-vars.service';
import { TranslationService } from './services/translation/translation.service';
import { WindowRefService } from './services/window-ref/window-ref.service';
import { uuidv4 } from './shared/utils';
import { MaterialConfiguratorService } from './services/material-configurator/material-configurator.service';

export const BROWSER_SESSION_ID = new InjectionToken<string>(
    'BROWSER_SESSION_ID'
);

export function initializeRecaptcha(recaptchaService: RecaptchaService) {
    // avoid loading recaptcha in test environment
    if (window.__WEBPACK_VAR_ENV === 'test') {
        return () => Promise.resolve();
    }
    return () => recaptchaService.loadScript();
}

export function initializeAppConfig(
    appConfigService: AppConfigService,
    i18nService: I18nService,
    window: WindowRefService,
    translationService: TranslationService,
    oneTrustService: OneTrustService
) {
    function fetchConfig() {
        return new Promise((resolve) => {
            const s = document.createElement('script');
            s.src = `app/config.js?v=${Date.now()}`;
            s.type = 'text/javascript';
            s.onload = resolve;
            document.head.appendChild(s);
        });
    }

    return () =>
        fetchConfig()
            .then(() => {
                const rawConfig = window.nativeWindow.kiteConfig;
                if (rawConfig === undefined) {
                    throw new Error('App config is not defined');
                }
                return appConfigService.setEnvConfig(rawConfig);
            })
            .then(() => {
                const culture = appConfigService.get('culture');
                const { supported, fallback } = culture;
                i18nService.setCultureInfo(
                    cultureInfoResolver(supported, fallback)
                );
                const url = `/locale/Base.${i18nService.getCulture()}.json`;
                return translationService.fetchJson(url);
            })
            .then(() => {
                return oneTrustService.loadScript();
            });
}

export function initializeAppThemes(themesService: ThemeVarsService) {
    return () => {
        const themes: AppThemes = {
            base: __WEBPACK_SASS_VARS_BASE,
            movistar: __WEBPACK_SASS_VARS_MOVISTAR,
            vivo: __WEBPACK_SASS_VARS_VIVO,
            O2_uk_local: __WEBPACK_SASS_VARS_O2UK,
            O2_germany: __WEBPACK_SASS_VARS_O2GR,
            O2_germany_local: __WEBPACK_SASS_VARS_O2GRLOCAL,
            windhellas: __WEBPACK_SASS_VARS_WINDHELLAS,
            tigo: __WEBPACK_SASS_VARS_TIGO,
            ttech: __WEBPACK_SASS_VARS_TTECH,
        };
        themesService.setThemes(themes);
    };
}

export function initializeMaterialConfiguration(materialConfiguratorService: MaterialConfiguratorService) {
    return () => {
        materialConfiguratorService.configureCustomMatIcons();
    };
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        AppRoutingModule,
        HttpClientModule,
    ],
    exports: [],
    providers: [
        ...httpInterceptorProviders,
        { provide: BROWSER_SESSION_ID, useFactory: () => uuidv4() },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppConfig,
            deps: [
                AppConfigService,
                I18nService,
                WindowRefService,
                TranslationService,
                OneTrustService,
            ],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppThemes,
            deps: [ThemeVarsService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeRecaptcha,
            deps: [RecaptchaService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeMaterialConfiguration,
            deps: [MaterialConfiguratorService],
            multi: true,
        },
    ],
})
export class AppModule implements DoBootstrap {
    constructor(
        private upgrade: UpgradeModule,
        private appConfigService: AppConfigService,
        private window: WindowRefService,
        private menuRouteEventHelper: MenuRouteEventHelper,
        private themes: ThemeVarsService
    ) {}

    async ngDoBootstrap(): Promise<void> {
        const DELAY_MS = 5000;
        const nativeWindow = this.window.nativeWindow;
        try {
            const appConfig = this.appConfigService.getConfig();
            const themes = this.themes.getThemeNames();
            const appContext = await bootstrap(appConfig, themes);
            const module = appContext.Ng1AppModuleFactory(appContext);

            // bootstrap angularjs application
            this.upgrade.bootstrap(document.documentElement, [module], {
                strictDi: false,
            });

            // TODO: create an async way to announce when the angularjs has bootstrapped
            // so others interested parties can start using it.
            // wait until AngularJS app is fully loaded and ng router is initialized.
            this.menuRouteEventHelper.initialize(this.upgrade);
        } catch (error) {
            nativeWindow.TID.log.error(
                'Error while bootstraping application: ',
                error
            );
            setTimeout(() => nativeWindow.location.reload(), DELAY_MS);
        }
    }
}
