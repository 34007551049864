import { Injectable } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root'
})
export class LogoutService {
    private $stateService: any;

    constructor(private upgrade: UpgradeModule){
        this.$stateService = this.upgrade.$injector.get('$state');
    }

    public navigate(location = false){
        this.$stateService.go('logout', null, { location });
    }
}
