import { Injectable } from '@angular/core';

export interface ICustomWindow extends Window {
    OptanonWrapper: any;
    XRegExp: any;
    M2M: any;
    Ext: any;
    _paq: Array<any>;
    CSS: any;
    MD5: any;
    md5: any;
    ClientJS: any;
    kiteConfig: any;
    BoxMessage: any;
    Kermit: any;
    TID: any;
    requestAnimationFrame: any;
    msCrypto: any;
    webkitRequestAnimationFrame: any;
    mozRequestAnimationFrame: any;
    msRequestAnimationFrame: any;
    webkitCancelAnimationFrame: any;
    mozCancelAnimationFrame: any;
    msCancelAnimationFrame: any;
    s: any;
    BigInteger: any;
    agGrid: any;
    _: any;
    __: (k: string) => string;
    d3: any;
    __WEBPACK_VAR_ENV: any;
    __WEBPACK_VAR_PACKAGE_VERSION: any;
    __WEBPACK_VAR_COMPILE_TIME: any;
    __WEBPACK_VAR_IS_ANGULAR_BUILD: any;
    __WEBPACK_VAR_CUSTOM_TRANSLATION_IDS: any;
    __WEBPACK_VAR_KITE_ENV: string;
    __WEBPACK_VAR_BRANCH_NAME: string;
    gmapsCB: any;
    gm_authFailure: any;
    timezoneJS: any;
    Handlebars: any;
}


function _window(): any {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRefService {
    get nativeWindow(): ICustomWindow {
        return _window();
    }
}
