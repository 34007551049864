import angular from 'angular';
import {
    MSGBOX_ERROR,
    MSGBOX_ERROR_GENERAL,
    MSGBOX_WARN_TITLE,
    LOGOUT_REDIRECT_TIMEOUT,
    isInternalServerError,
    isSessionExpiredError,
} from '../services/http-interceptors/utils';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { LiteErrorService } from '../services/error/lite-error.service';
import { ErrorService } from '../services/error/error.service';
import { TranslationService } from '../services/translation/translation.service';
import { BroadcastService } from '../services/broadcaster/broadcast.service';
import { UpgradeModule } from '@angular/upgrade/static';
import { Observable, tap } from 'rxjs';

@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {

    constructor(
        private userService: UserService,
        private liteErrorService: LiteErrorService,
        private errorService: ErrorService,
        private translationService: TranslationService,
        private broadcastService: BroadcastService,
        private upgrade: UpgradeModule,
    ){}

    defaultHttpErrorHandler({ status, errorCode, message }: any, httpErrorHandlerOptions?: any): void {
        const errMessage = this.errorService.getMessageFromHttpCode(status, errorCode);
        // Show message errors
        this.errorService.showErrorMessage(errMessage || message, undefined, undefined, httpErrorHandlerOptions);
    }

    getErrorMessageFromStatusCode(code: string): string {
        return this.translationService.exists(MSGBOX_ERROR + code)
            ? this.translationService.getTranslation(MSGBOX_ERROR + code)
            : this.translationService.getTranslation(MSGBOX_ERROR_GENERAL);
    }

    getHttpStatus(response: any): any {
        return response.status ?? '';
    }

    extractMessageFromResponse(data: any, status: any) {
        return this.liteErrorService.getMessage(data) || data.reason || (!isInternalServerError(status) && data.message);
    }

    getGenericErrorMessage(status: string): string {
        return (this.translationService.exists(MSGBOX_ERROR + status))
                        ? this.translationService.getTranslation(MSGBOX_ERROR + status)
                        : this.translationService.getTranslation(MSGBOX_ERROR_GENERAL);
    }

    getErrorMessageFromResponse(data: any, status: any){
        if (data == null){
            return '';
        }
        const result = this.errorService.getMessage(data);
        if (result) {
            return result;
        }
        if (data.reason) {
            return data.reason;
        }
        return isInternalServerError(status) && data.message;
    }

    sessionExpiredErrorHandler(message: string): void {
        // Show specific message for expired session
        this.errorService.showErrorMessage(message, () => {
            this.broadcastService.publish(this.broadcastService.messages.SPINNER_SHOW);

            // Workaround to solve a circular dependency when $route service is injected with normal DI.
            const location = this.upgrade.$injector.get('locationService');
            const logoutService = this.upgrade.$injector.get('logoutService');

            // The session is expired -> redirect to login page
            const path = '/logout';
            const currentPath = location.path();

            setTimeout(($route: any) => {
                if ($route.current && (currentPath === path || currentPath === `${path}/`)) {
                    //reload current route
                    location.reload();
                } else {
                    logoutService.navigate();
                }
            }, LOGOUT_REDIRECT_TIMEOUT);
        }, this.translationService.getTranslation(MSGBOX_WARN_TITLE));
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap({ error: (event) => this.errorHandler(req, event) }));
    }

    errorHandler(request: HttpRequest<any>, event: HttpErrorResponse) {
        if (event instanceof HttpErrorResponse){
            const searchParams = new URL(event.url || '').searchParams;
            const noIgnoreErrors = [null, 'false'].includes(searchParams.get('ignoreErrors'));

            // TODO: should be passed in the query params of the request.
            const shouldUseHttpErrorHandler = searchParams.get('useHttpErrorHandler') === 'true';

            let eventData;

            if (request.responseType === 'arraybuffer') {
                try {
                    eventData = JSON.parse(
                        String.fromCharCode.apply(null, Array.from(new Uint8Array(event.error)))
                    );
                } catch(err) {
                    // SONAR
                    // Why is this empty?
                }
            }

            if (!this.userService.isAuthenticated() && noIgnoreErrors) {
                let message,
                    errorCode,
                    errorParams;

                try {
                    // Error code management
                    eventData = eventData || angular.fromJson(event.error);
                    errorCode = this.liteErrorService.getErrorCodeFromResponse(eventData);
                    errorParams = this.liteErrorService.getParameters(eventData);
                    message = this.extractMessageFromResponse(eventData, event.status);
                } catch (e) {
                    message = this.getGenericErrorMessage(event.status.toString());
                }

                const errorEvent = {
                    message,
                    errorCode,
                    errorParams,
                    status: event.status,
                    requestHeaders: request.headers,
                    responseHeaders: event.headers,
                };

                // Chrome is ok in sending a response HTTP 504 against host unreachable situation
                // but Firefox has a different behavior. It simply do nothing.
                // As a result, Angular ends up rejecting http call promise by timeout reason
                // granting not response.
                // To work this around, we create an empty object as response so the error handling chain to
                // not break.
                (event = event || {});

                // TODO: Revisar porque no deberiamos mutar el objeto event.
                (event as any).errorEvent = errorEvent;

            } else {
                if (event) {
                    const status = this.getHttpStatus(event);
                    const data = event.error;
                    const message = this.getErrorMessageFromResponse(data, status) || this.getErrorMessageFromStatusCode(status);
                    const errorCode = this.errorService.getErrorCodeFromResponse(data);

                    const errorEvent = { status, message, data, errorCode };
                    (event as any).errorEvent = errorEvent;

                    if (isSessionExpiredError(errorCode)) {
                        this.sessionExpiredErrorHandler(message);
                    } else if (shouldUseHttpErrorHandler) {
                        // TODO:
                        // should pass httpErrorHandlerOptions in the query params of the request.
                        const httpErrorHandlerOptions = JSON.parse(searchParams.get('httpErrorHandlerOptions') || '{}');
                        this.defaultHttpErrorHandler(errorEvent, httpErrorHandlerOptions);

                    } else {
                        // SONAR
                    }
                } else {
                    const errorEvent = { message: this.translationService.getTranslation(MSGBOX_ERROR_GENERAL) };

                    // TODO: Revisar porque no deberiamos reescribir el objeto event.
                    (event as any) = { errorEvent };

                    this.defaultHttpErrorHandler(errorEvent);
                }
            }
            return event;
        }
        return event;
    }
}
