export type DenormalizedAuthorization = { prefix: string, credential: string };
export type NormalizedCredential = { identity: string, token: string };

export function deNormalizeAuthorization(authorization: string): DenormalizedAuthorization {
    const out = authorization.split(' ');
    return {
        prefix: out[0],
        credential: out[1],
    };
}

export function normalizeAuthorization(prefix: string, credential: string): string {
    return [prefix, credential].join(' ');
}

export function normalizeCredential(identity: string | null, password: string | null): string {
    return window.encodeURIComponent([identity, password].join(':'));
}

export function deNormalizeCredential(credential: string): NormalizedCredential {
    const out = window.decodeURIComponent(credential).split(':');
    return {
        identity: out[0],
        token: out[1],
    };
}
