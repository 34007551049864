

/**
 * Helper class to be used in the aplication of one to many mixins to a base class
 *
 * @export
 * @class MixinBuilder
 */
class MixinFactory {
    private superclass: any;
    constructor(superclass: any) {
        this.superclass = superclass;
    }

    with(mixins: any[] | any) {
        mixins = Array.isArray(mixins) ? mixins : [mixins];
        return mixins.reduce((c: any, m: any) => m(c), this.superclass);
    }
}

export const mix = (superclass: any) => new MixinFactory(superclass);
