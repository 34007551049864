/**
 * Use along Array.sort()
 * For two inputs, returns which one is before alphabetically
 *
 * @export
 * @param {*} a
 * @param {*} b
 * @returns
 */
export function sorterFn (propName: any) {
   return (a: any, b: any) => {
        const valA = a[propName].toUpperCase().replace(/\s/g, '');
        const valB = b[propName].toUpperCase().replace(/\s/g, '');
        if (valA > valB) {
            return 1;
        } else if (valA < valB) {
            return -1;
        } else {
            return 0;
        }
    };
}
