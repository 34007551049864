import { UserService } from '@services/user/user.service';
import { HttpStatusCode } from '@shared/interfaces';
import { LogoutService } from '@services/logout/logout.service';
import { curry } from 'lodash-es';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { UpgradeModule } from '@angular/upgrade/static';
import { WindowRefService } from '../services/window-ref/window-ref.service';
import { Injectable } from '@angular/core';

const excludeRoutes = [
    'login',
    'activate',
    'changepassword',
    'lostpassword',
    'expiredpassword',
];

const isNoLoginPath = curry((paths: Array<string>, currentPath: string) =>
    paths.map(r => `/account/${r}`)
        .concat(['/logout', '/'])
        .every(path => path !== currentPath));

const isNoLoginPathUrl = isNoLoginPath(excludeRoutes);

const shouldLogout = (url: string | undefined, isAuthenticated: boolean, path: string) => {
    const params = new URLSearchParams(url || '');
    const ignoreRedirectDomainError = params.get('ignoreRedirectDomainError') === 'true';
    return !ignoreRedirectDomainError && (isAuthenticated || isNoLoginPathUrl(path));
};

const is401Error = (event: HttpErrorResponse) =>
    event instanceof HttpErrorResponse && event.status === HttpStatusCode.UNAUTHORIZED;

@Injectable()
export class ResponseError401Interceptor implements HttpInterceptor {
    private $location: any;
    private logger: any;

    constructor(
        private userService: UserService,
        private upgrade: UpgradeModule,
        private window: WindowRefService,
        private logoutService: LogoutService) {
            this.$location = this.upgrade.$injector.get('$location');
            this.logger = this.window.nativeWindow.TID.log;
        }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap({ error: (event) => this.errorHandler(req, event) }) );
    }

    errorHandler(request: HttpRequest<any>, event: HttpErrorResponse) {
        if (is401Error(event)) {
            if (event.url?.includes('/api/core/user/me')) {
                this.userService.clearUserData();
            }

            const isAuthenticated = this.userService.isAuthenticated();
            const path = this.$location.path();

            if (shouldLogout((event.url || request.url), isAuthenticated, path)) {
                this.logoutService.navigate();
            }
        }
        return event;
    }
}
