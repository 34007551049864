/**
 * Returns true if the value passed in is a number.
 * It consider input values in number type of string type.
 *
 * Example:
 * It will return false in the following scenarios:
 * isNumber(null)
 * isNumber(undefined)
 * isNumber('')
 * isNumber(NaN)
 * isNumber('asdf')
 * isNumber('121aaa')
 * isNumber('1212.aa')
 * isNumber(Infinity)
 * isNumber(-Infinity)
 * isNumber(true)
 * isNumber(false)
 *
 * It will return true in the following scenarios:
 * isNumber(0)
 * isNumber('0')
 * isNumber(1231)
 * isNumber('12312')
 * isNumber(12e10)
 * isNumber('12e10')
 *
 * @export
 * @return {*}
 */
export function isNumber(value: any){
    return value !== null &&
            value !== '' &&
            typeof value !== 'boolean'
            && Number.isFinite(Number(value));
}
