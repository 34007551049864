import { HttpStatusCode } from '@shared/interfaces';

export const SESSION_EXPIRED_ERROR = '56014';
export const REQUIRE_TWO_FACTOR_ERROR = '56017';
export const PASS_EXPIRED_ERROR = '56013';

export const LOGOUT_REDIRECT_TIMEOUT = 100;

export const MSGBOX_ERROR = 'MsgBox.error';
export const MSGBOX_ERROR_GENERAL = 'MsgBox.errorGeneral';
export const MSGBOX_WARN_TITLE = 'MsgBox.warnTitle';

export const isInternalServerError = (status: number) => status === HttpStatusCode.INTERNAL_SERVER_ERROR;
export const isStatus403Error = (status: number) => status === HttpStatusCode.FORBIDDEN;

const checkErrorType = (errorType: any) => (code: any) => code && code.indexOf(errorType) >= 0;

export const isPasswordExpiredError = checkErrorType(PASS_EXPIRED_ERROR);
export const isRequiresTwoFactorError = checkErrorType(REQUIRE_TWO_FACTOR_ERROR);
export const isSessionExpiredError = checkErrorType(SESSION_EXPIRED_ERROR);
