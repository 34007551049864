
/**
 * Returns the size of an element and its position relative to the viewport.
 *
 * @export
 * @param {*} element
 * @returns {DOMRect}
 */
export function getBoundingClientRect(element: HTMLElement): DOMRect {
    if (element && element.getBoundingClientRect) {
        return element.getBoundingClientRect();
    } else {
        return { top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0 } as DOMRect;
    }
}
