export default function CookieLanguageDetector() {
    const langCookie = getDocumentCookies().lang;
    if (langCookie) {
        const normalizedLang = normalizeCookieValue(langCookie) || null;
        // Remove cookie and save lang to localstorage
        window.localStorage.setItem('lang', normalizedLang);
        document.cookie = 'lang=';
        return normalizedLang;
    }
    return false;
}

export function getDocumentCookies() {
    let result;
    const cookies = document.cookie;
    if (cookies !== '') {
        result = cookies.split(';')
            .reduce((o: any, cookie: string) => {
                const p = cookie.split('=');
                o[p[0].trim()] = p[1].trim();
                return o;
            }, {});
    }
    return result || {};
}

export function normalizeCookieValue(lang: string) {
    let result;
    try {
        result = JSON.parse(decodeURI(lang));
    } catch (ex) {}
    return result || lang;
}
