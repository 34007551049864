import { AppConfigService } from '../app-config/app-config.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Observable, firstValueFrom } from 'rxjs';

type RecaptchaConfigSize = 'invisible';
type RecaptchaConfigTheme = 'dark' | 'light';
type RecaptchaConfigBadge = 'bottomleft';
type RecaptchaConfigApiVersion = 2 | 3;

export interface RecaptchaConfig {
    enabled: boolean;
    key: string;
    url: string;
    theme: RecaptchaConfigTheme;
    required: boolean;
    size: RecaptchaConfigSize;
    badge: RecaptchaConfigBadge;
    apiVersion: RecaptchaConfigApiVersion;
}

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    static NO_API_AVAILABLE_ERROR: string = 'no recaptcha-api available in this site';
    static NO_RECAPTCHA_END_POINTS: string[] = [
        '/noauthrequest',
    ];

    private url = '';
    private key = '';
    private recaptchaConfig$ = this.appConfigService.getAsync<RecaptchaConfig>('recaptchaConfig');

    private config!: RecaptchaConfig;
    private checkRecaptchaIsDisabled: boolean = false;
    private nativeWindow: Window = this.window.nativeWindow as any;

    errors = {
        noApiAvailable: 'No recaptcha-api available in this site',
        invalidUrlOrKey: 'Invalid url or key',
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private window: WindowRefService,
        private appConfigService: AppConfigService,
        private location: Location
        ) {
        this.checkRecaptchaIsDisabled = this.isRecaptchaDisabledByUrlPath();
        this.recaptchaConfig$?.subscribe(config => (this.config = { ...config }));
    }

    public async loadScript(): Promise<any> {
        await firstValueFrom(this.recaptchaConfig$);

        if (this.checkRecaptchaIsDisabled || !this.config.enabled || this.isLoaded()) {
            return Promise.resolve('recaptcha-api: already loaded or disabled');
        }

        if (!this.config?.url || !this.config.key) {
            return Promise.reject(new Error(this.errors.invalidUrlOrKey));
        }

        return this.appendScriptTag(this.config.url, this.config.key)
            .catch(console.error.bind(console));
    }

    public exec(bypass = false, key = this.config.key, action = ''): Promise<any> {
        let result;
        const window = this.nativeWindow as any;
        if (this.config.enabled && !bypass) {
            if (typeof window.grecaptcha !== 'undefined') {
                result = window.grecaptcha.execute(key, { action: action });
            } else {
                result = new Promise(resolve => {
                    const c = '___grecaptcha_cfg';
                    window[c] = window[c] || {};
                    (window[c]['fns'] = window[c]['fns']||[]).push(() => {
                        window.grecaptcha.execute(key, { action })
                            .then((d: any) => resolve(d));
                    });
                });
            }
        } else {
            result = Promise.resolve();
        }
        return result;
    }

    public isLoaded(): boolean {
        const scripts = this.document.getElementsByTagName('script');
        const scriptArr = [].slice.call(scripts);
        return scriptArr.some((elem: any) => elem && (elem['kiterecaptcha'] || elem.getAttribute('kiterecaptcha')));
    }

    public isRecaptchaDisabledByUrlPath(): boolean {
        return RecaptchaService.NO_RECAPTCHA_END_POINTS
            .some(path => this.location.path().startsWith(path));
    }

    private appendScriptTag(url: string, key: string): Promise<void> {
        const document = this.document;
        return new Promise<void>((resolve) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = url + key;
            script.setAttribute('kiterecaptcha', key);
            script.onload = () => resolve();

            const s = document.getElementsByTagName('script')[0];
            s.parentNode!.insertBefore(script, s);
            return;
        });
    }
}
