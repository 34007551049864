/**
 * Reusable safeApply function.
 * Assign this function to the scope where you want to be used.
 *
 * @export
 * @param {*} fn
 */
export function safeApply(this: any, fn: Function) {
    const phase = this.$root.$$phase;
    if (phase === '$apply' || phase === '$digest') {
        if(fn && (typeof(fn) === 'function')) {
            fn();
        }
    } else {
        this.$apply(fn);
    }
}
