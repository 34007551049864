import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';

@Directive({
    selector: '[kiteId]',
    standalone: true,
})
export class KiteIdDirective implements OnInit, OnChanges {

    @Input() kiteId?: string = '';
    @Input() kiteIdValue?: string = '';

    constructor(
        private elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        this.elementRef.nativeElement.setAttribute('data-m2mid', this.kiteId);
        if (this.kiteIdValue) {
            this.elementRef.nativeElement.setAttribute('data-m2mvalue', this.kiteIdValue);
        }
    }
    ngOnChanges(): void {
        this.elementRef.nativeElement.setAttribute('data-m2mid', this.kiteId);
        if (this.kiteIdValue) {
            this.elementRef.nativeElement.setAttribute('data-m2mvalue', this.kiteIdValue);
        }
    }
}
