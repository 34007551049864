/**
 * Add favicon dynamically according to the current theme.
 *
 * Note:
 * MS-Edge browser seems to have a hard time resolving the link[href] if this contains
 * an uppercase character. So replacing to lowercase should avoid any issue.
 *
 */
export function setCustomFavicon(theme?: string) {
    const link: HTMLLinkElement | null = document.head.querySelector('link[href^=favicon]');
    if (link && theme) {
        link.href = `favicon/${theme.toLowerCase()}.png`;
    }
}
