export default function UrlLanguageDector() {
    const parts = window.location.hash.split('?');
    return (parts.length > 1) ? fromQueryString(parts[1]) : false;
}

export function fromQueryString(queryString: string): boolean {
    let lang: boolean = false;
    queryString.split('&').find((param: any) => {
        const p = param.split('=');
        if (p[0] == 'lang') {
            lang = p[1];
            return true;
        }
        return false;
    });
    return lang;
}
