import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const iconsMap = {
    kite_reload: 'reload',
    kite_download: 'download',
    kite_excel: 'xls',
    kite_csv: 'csv',
    kite_report_request: 'report-request',
    kite_loading: 'three-dots',
    kite_column_config: 'column-config',
    kite_info: 'info',
    kite_alert_info: 'alert-info',
    kite_alert_info_inverted: 'alert-info-inverted',
    kite_alert_success: 'alert-success',
    kite_alert_error: 'alert-error',
    kite_first_page_btn: 'first-page',
    kite_last_page_btn: 'last-page',
    kite_next_page_btn: 'next-page',
    kite_prev_page_btn: 'prev-page',
    kite_add: 'add',
    kite_filled_add: 'filled-add',
    kite_admiration: 'admiration',
    kite_target: 'target',
    kite_radar: 'radar',
    kite_robot: 'robot',
    kite_speaker: 'speaker',
    kite_back_detail: 'back-detail',
    kite_edit: 'edit',
    kite_cross: 'cross',
    kite_email: 'email',
    kite_sim_search: 'sim-search',
    kite_sms: 'sms',
    kite_pool: 'pool',
    kite_pools: 'pools',
    kite_apn: 'apn',
    kite_sim: 'sim',
    kite_folder: 'folder',
    kite_coins: 'coins',
    kite_select_item: 'click-hand-icon',
};
const ICONS_PATH = '/src/styles/images/icons/';

@Injectable({
    providedIn: 'root',
})
export class MaterialConfiguratorService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {}

    configureCustomMatIcons(): void {
        const iconKeys = Object.keys(iconsMap);
        iconKeys.forEach((iconName: string) => {
            const fileName = iconsMap[iconName as keyof typeof iconsMap];

            this.matIconRegistry.addSvgIcon(
                iconName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `${ICONS_PATH}${fileName}.svg`
                )
            );
        });
    }
}
