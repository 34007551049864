import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
    providedIn: 'root'
})
export class AppVersionService {
    private _codeName: string = '';
    private release_version: string = '';

    get full () {
        return this.window.nativeWindow.__WEBPACK_VAR_PACKAGE_VERSION;
    }

    get major () {
        const parts = this.window.nativeWindow.__WEBPACK_VAR_PACKAGE_VERSION.split('.');
        return Number.parseInt(parts[0]) || 0;
    }

    get minor () {
        const parts = this.window.nativeWindow.__WEBPACK_VAR_PACKAGE_VERSION.split('.');
        return Number.parseInt(parts[1]) || 0;
    }

    get patch () {
        const parts = this.window.nativeWindow.__WEBPACK_VAR_PACKAGE_VERSION.split('.');
        return Number.parseInt(parts[2]) || 0;
    }

    get codeName () {
        const parts = this.window.nativeWindow.__WEBPACK_VAR_PACKAGE_VERSION.split('.');
        return this._codeName;
    }

    set codeName (value: string) {
        this._codeName = value;
    }

    get backendReleaseVersion () {
        return this.release_version;
    }

    set backendReleaseVersion (value: string) {
        this.release_version = value;
    }

    constructor(private window: WindowRefService){}
}
