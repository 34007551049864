import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '@services/app-config/app-config.service';
import { isEmpty, isFinite, isString } from 'lodash-es';
import { DialogComponent } from '../../shared/components/dialogs/base-dialog/dialog.component';
import { FormatService } from '../format/format.service';
import { TranslationService } from '../translation/translation.service';
import { WindowRefService } from '../window-ref/window-ref.service';

const intNumberRe = /^[0-9]+$/i;
const interpolate = (str: string, ...rest: any[]) =>
    str.replace(/\{(\d+)\}/g, (m, i) => rest[i]);
const ParamsListTemplate = `<ul>{{#each this}}<li><span>{{key}}:</span><span> {{reason}}</span></li>{{/each}}</ul>`;
export const DEFAULT_DIALOG_WIDTH = '600px';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    /**
     * Name of the attribute holding the key of the error params stored in the 'errorMessages' array. Ex:
     *
     * "errorMessages":[{
     *      "entityId": "APN",
     *      "description": "apn9.movistar.es"
     *    }, {
     *      "entityId": "VPN",
     *      "description": "testvpn3"
     *    }, {
     *      "entityId": "Customer",
     *      "description": "customer_alarm_com_3____________"
     *    }
     * ]
     *
     */
    private errorMessagesKey: string = 'entityId';

    /**
     * Name of the attribute holding the value of the error params stored in the 'errorMessages' array.
     * @see errorMessagesKey example
     */
    private errorMessagesValue: string = 'description';

    private constant: Record<string, string> = {
        codeNS: 'Error.code.{0}',
        messageNS: 'Error.message.{0}',
        paramsNS: 'Error.parameters.{0}',
    };

    private translate: any;
    private appConfig: AppConfigService;

    constructor(
        private translationService: TranslationService,
        private formatService: FormatService,
        private appConfigService: AppConfigService,
        private window: WindowRefService,
        private matDialog: MatDialog
    ) {
        this.appConfig = this.appConfigService;
        this.translate =
            translationService.getTranslation.bind(translationService);
    }

    public getMessage(errorData: any): string | null {
        if (!Number.isNaN(Number.parseInt(errorData, 10))) {
            return this.getMessageFromCode(errorData.toString());
        }

        let code =
            (errorData.error && errorData.error.toString()) ||
            (errorData.exceptionId && errorData.exceptionId.toString());
        let params = this.getParameters(errorData);
        let namedParams = this.getNamedParams(
            errorData.errorMessages,
            this.errorMessagesKey,
            this.errorMessagesValue
        );
        let message = this.getMessageFromCode(code, namedParams);

        if (params && !isEmpty(params)) {
            if (!message) {
                message = (errorData && errorData.message) || '';
            }
            message += this.getParamsText(params);
        }

        return message;
    }

    /*
     *  Returns a formatted text translating the received parameters if the translation exists
     */
    public getParamsText(params: any): string {
        let ret = '';
        let compileTplFn =
            this.window.nativeWindow.Handlebars.compile(ParamsListTemplate);

        let paramsFormatted: any[] = [];

        // extract translations from each parameter
        this.window.nativeWindow._(params).each((reasons: string, key: any) => {
            let keyLiteral: string = interpolate(this.constant.paramsNS, key);
            let keyFormatted = this.getLiteral(keyLiteral);

            // process key
            if (!keyFormatted) {
                keyFormatted =
                    key ||
                    this.translate(
                        interpolate(this.constant.messageNS, 'globalError')
                    );
            }

            //process reasons
            let reasonFormatted = '';
            this.window.nativeWindow._(reasons).each((reason: string) => {
                let reasonLiteral = interpolate(this.constant.paramsNS, reason);
                let reasonPart = this.getLiteral(reasonLiteral);

                if (reasonPart) {
                    reasonFormatted += reasonPart + '. ';
                } else {
                    reasonFormatted = reason;
                }
            });

            if (reasonFormatted) {
                //add reason to the list
                paramsFormatted.push({
                    key: keyFormatted,
                    reason: reasonFormatted,
                });
            }
        });

        if (paramsFormatted.length > 0) {
            ret = compileTplFn(paramsFormatted);
        }

        return ret;
    }

    /**
     * Transform an array of name/value error params to an object. Ex:
     *      [{ "entityId" : "APN", "description" : "test1"}, { "entityId" : "VPN", "description" : "test.com"}]
     * returns:
     *      {"APN": "test1", "VPN": "test.com"}
     * @param {Array} errorMessages array of name/value params.
     * @param {String} key the name of the attribute holding the key of the error param.
     * @param {String} value the name of the attribute holding the value of the error param.
     * @return {Object} named object, or null if empty.
     */
    public getNamedParams(
        errorMessages: string,
        key: string,
        value: string
    ): string | null {
        let params = null;

        if (Array.isArray(errorMessages)) {
            params = errorMessages.reduce((o, v) => {
                // To remove bad chars, just in case E/// sends bad error keys
                const errorMsgKey = this.formatService.accentsTidy(v[key]);
                if (errorMsgKey) {
                    //TODO: resolve M2M.lib.Errors.formatParamError
                    o[errorMsgKey] = M2M.lib.Errors.formatParamError(
                        errorMsgKey,
                        v[value]
                    );
                }
                return o;
            }, {});
        }

        return params;
    }

    /**
     * Gets the error code from a response.
     * Returns the error code in KABCDEE format, or null if not found.
     *
     * @param {*} response
     * @returns
     * @memberof ErrorService
     */
    public getErrorCodeFromResponse(errorObj: any): string | null {
        if (errorObj == null) return null;
        return errorObj.error &&
            !Number.isNaN(Number.parseInt(errorObj.error, 10)) &&
            this.isValidErrorCode(errorObj.error)
            ? this.code2String(errorObj.error)
            : null;
    }

    /**
     * Returns a message from a http error code
     * @param {Number} code The http code number.
     * @param {Number} errorCode The error code from json response.
     */
    public getMessageFromHttpCode(
        code: string,
        errorCode: number
    ): string | null {
        let message = null;

        if (['401', '403', '404', '429'].includes(code)) {
            message = this.translate('MsgBox.error' + code);
        } else if (code == '400' && errorCode == 5702) {
            message = this.translate('MsgBox.error429');
        }

        return message;
    }

    /**
     * error data format: {"firstName": {"isEmpty":"Value is required and can't be empty"}}
     * returns {"firstName": ["isEmpty"]}
     *
     * @param {*} errorData BE Response.
     * @returns
     * @memberof ErrorService
     */
    public getParameters(errorData: any): any {
        const params = {};

        getParamsKeyRecursively(params, [], errorData.validationErrors);

        return params;

        function getParamsKeyRecursively(
            result: any,
            keyParts: any[],
            paramObj: any = {}
        ) {
            let key = keyParts.join('.');

            Object.entries(paramObj).forEach(([innerKey, innerValue]) => {
                if (isString(innerValue)) {
                    //end node -> join keys with dot and compose the result of this branch
                    if (!result[key]) {
                        result[key] = [];
                    }
                    result[key].push(innerKey);
                } else {
                    // accumulate key parts of the branch
                    keyParts.push(innerKey);
                    getParamsKeyRecursively(result, keyParts, innerValue);
                    keyParts.pop();
                }
            });
        }
    }

    public getErrorParams(errorData: any) {
        return (
            errorData &&
            errorData.errorMessages &&
            this.getNamedParams(errorData.errorMessages, 'name', 'value')
        );
    }

    /**
     * Checks if the watcher has events with registered errors
     * @param {Object} watcher The watcher to be checked.
     */
    public hasWatcherErrors(watcher: any): boolean {
        let ret = false;

        if (watcher && watcher.eventList && watcher.eventList.length > 0) {
            ret = this.window.nativeWindow._(watcher.eventList).any(function (
                event: any
            ) {
                return event.eventData && event.eventData.hasFailures;
            });
        }

        return ret;
    }

    /**
     * Converts error code from number to string. Makes a zero padding up to 7 digits
     *
     * @param {*} code
     * @returns
     * @memberof ErrorService
     */
    public code2String(code: string | number): string {
        // Zero padding. Error codes must have 7 digits
        return isFinite(code) ? ('000000' + code).slice(-7) : code.toString();
    }

    /**
     * Return the corresponding translated message from an error code.
     * @param {*} code
     * @returns
     * @memberof ErrorService
     */
    public getMessageFromCode(
        code: string | number,
        namedParams?: any
    ): string | null {
        let message = null;

        if (code) {
            code = this.code2String(code);

            //check code format
            if (this.isValidErrorCode(code)) {
                let literal = this.getErrorLiteral(code);

                if (literal) {
                    message = this.getLiteral(literal);
                    if (namedParams) {
                        // Formats error message with named params. Ex:
                        // _.sprintf('Hello %(name)s, your email is %(email)s',
                        //           {'name': 'John', 'email': 'john@test.com'});
                        // -> 'Hello John, your email is john@test.com'
                        try {
                            message = this.window.nativeWindow._.sprintf(
                                message,
                                namedParams
                            );
                        } catch (e) {
                            // sprintf fails if named param is not found on error object
                            // Error ignored
                        }
                    }
                } else {
                    message = this.getUnregisteredMessage(code);
                }
            }
        }
        return message;
    }

    /*
     * Get a general message based on code parts
     * code format: KABCD
     */
    public getUnregisteredMessage(code: string): string {
        let codeStr = this.code2String(code);

        if (Number.isNaN(Number.parseInt(codeStr[0], 10))) {
            codeStr = '0';
        }

        const K = this.translate(
            interpolate(this.constant.codeNS, 'K.' + codeStr[0])
        ); // type
        const A = codeStr[1]; // subtype

        switch (A) {
            case '0': // Core generic cases
            case '5': // BE generic cases
                const KAB = interpolate(
                    this.constant.codeNS,
                    'KAB.' + codeStr.slice(0, 3)
                );
                const CD = interpolate(
                    this.constant.codeNS,
                    'CD' + codeStr[1] + '.' + codeStr.slice(3, 5)
                ); // CD

                if (this.isLiteralDefined(KAB)) {
                    if (!this.isLiteralDefined(CD)) {
                        return this.translate(
                            interpolate(
                                this.constant.messageNS,
                                'unregisteredErrorGenericKAB'
                            ),
                            this.translate(KAB)
                        );
                    }
                    return this.translate(
                        interpolate(
                            this.constant.messageNS,
                            'unregisteredErrorGeneric'
                        ),
                        this.translate(KAB),
                        this.translate(CD)
                    );
                } else {
                    return this.translate(
                        interpolate(
                            this.constant.messageNS,
                            'unregisteredError'
                        ),
                        K
                    );
                }
            default: // detailed errors not registered
                return this.translate(
                    interpolate(this.constant.messageNS, 'unregisteredError'),
                    K
                );
        }
    }

    public getErrorMessages(
        errorData: any,
        printRawParam: any,
        summarize: any,
        summarizedErrorData: any,
        hideErrorLevel: boolean
    ): Array<any> {
        let errors: any = errorData || [];
        let messages: any[] = [];

        if (summarize) {
            let totalErrors = errors.length,
                maxErrors = this.appConfig.get('maxAsyncErrors'),
                lastErrors =
                    totalErrors > maxErrors ? totalErrors - maxErrors : 0,
                initial = this.window.nativeWindow
                    ._(errors)
                    .initial(lastErrors),
                last = this.window.nativeWindow._(errors).last(lastErrors);

            if (last.length) {
                let errorGroups = this.window.nativeWindow
                    ._(last)
                    .groupBy(function (error: any) {
                        return error.code;
                    });

                let summary = this.window.nativeWindow
                    ._(errorGroups)
                    .map(function (group: any[]) {
                        let item = group[0];
                        item.total = group.length;
                        return item;
                    });

                errors = initial.concat(summary);
            }
        }

        // Summarized error data from BE
        if (summarizedErrorData) {
            this.window.nativeWindow._(summarizedErrorData).each(function (
                group: any
            ) {
                group.errors[group.errors.length - 1].total =
                    group.errorCount - group.errors.length + 1;
                errors = errors.concat(group.errors);
            });
        }

        this.window.nativeWindow._(errors).each((error: any) => {
            let message = [],
                printRaw = printRawParam;

            if (
                error.reason &&
                this.window.nativeWindow._.isArray(error.reason)
            ) {
                // For preprocessed messages)
                message = error.reason;
            } else {
                let code = error.code || error.reasonCode || 0;

                // If there is no translation for this error code. The error code must be 0 to show the raw message.
                if (code && this.getErrorLiteral(code) === null) {
                    code = 0;
                }

                if (code === 0) {
                    printRaw = true;
                }

                // Errors relating to fields
                if (
                    code >= this.appConfig.get('minErrorRange') &&
                    code <= this.appConfig.get('maxErrorRange') &&
                    error.description
                ) {
                    // If the column is not an integer then the column is referring to the field name.
                    if (
                        error.column &&
                        this.window.nativeWindow._.isNaN(
                            parseInt(error.column, 10)
                        )
                    ) {
                        error.field = error.column;
                    } else {
                        error.field = error.description;
                    }

                    if (printRaw) {
                        if (
                            error.description ===
                            this.appConfig.get('globalIdentifier')
                        ) {
                            error.description = this.translate(
                                'Error.message.globalError'
                            );
                        } else {
                            error.description = this.translate(
                                'Error.message.errorInField',
                                error.field
                            );
                        }
                        error.description += this.getMessageFromCode(code);
                    }
                }

                if (error.level && !hideErrorLevel) {
                    if (printRaw) {
                        message.push({
                            label: this.window.nativeWindow._.capitalize(
                                error.level
                            ),
                            translated: true,
                        });
                    } else {
                        message.push({
                            label: 'Error.Stock.Severity.' + error.level,
                        });
                    }
                    message.push({ label: ': ', translated: true });
                }

                let hasLine = error.line !== undefined,
                    hasColumn = error.column !== undefined;

                if (error.total === undefined && (hasLine || hasColumn)) {
                    message.push({ label: ' ', translated: true });

                    if (printRaw) {
                        let errorLocation = '(';

                        if (hasLine) {
                            errorLocation += this.translate(
                                'Error.Stock.Line',
                                error.line
                            );
                        }
                        if (hasColumn) {
                            if (hasLine) {
                                errorLocation += ', ';
                            }
                            errorLocation += this.translate(
                                'Error.Stock.Column',
                                error.column
                            );
                        }
                        errorLocation += ')';
                        message.push({
                            label: errorLocation,
                            translated: true,
                        });
                    } else {
                        let locationKey = 'Error.Stock.',
                            params = [];

                        if (hasLine) {
                            locationKey += 'Line';
                            params.push(error.line);
                        }
                        if (hasColumn) {
                            locationKey += 'Column';
                            params.push(error.column);
                        }

                        message.push({ label: locationKey, params: params });
                    }
                }

                if (message.length > 0) {
                    message.push({ label: ' - ', translated: true });
                }

                if (printRaw) {
                    if (error.description) {
                        message.push({
                            label: error.description,
                            translated: true,
                        });
                    }
                } else {
                    let errorObject: any = { errorCode: code };
                    if (error.field) {
                        errorObject.field = error.field;
                    }

                    if (error.params) {
                        errorObject.errorMessages = error.params;
                    }

                    message.push(errorObject);
                }

                if (error.entityId && printRaw) {
                    let paramsAux;

                    message.push({ label: ' ', translated: true });

                    if (error.entity) {
                        paramsAux = [error.entity, error.entityId];
                    } else {
                        paramsAux = [error.entityId];
                    }

                    paramsAux.unshift('Error.Stock.entity');
                    message.push({
                        label: this.translate.apply(this.translate, paramsAux),
                        translated: true,
                    });
                }

                if (error.total && error.total > 1) {
                    message.push({ label: '. ', translated: true });
                    if (printRaw) {
                        message.push({
                            label: this.translate(
                                'Error.Stock.total',
                                this.window.nativeWindow.M2M.lib.Format.int2String(
                                    error.total - 1
                                )
                            ),
                            translated: true,
                        });
                    } else {
                        message.push({
                            label: 'Error.Stock.total',
                            params: [error.total - 1],
                        });
                    }
                }

                // If the only info is the reason
                if (message.length == 0 && error.reason) {
                    message.push({ label: error.reason, translated: true });
                }
            }

            if (error.total === undefined) {
                // The error level "ERROR" is considered a fatal error that not applies to a particular sim
                // and should not be computable as an error
                error.total = error.level === 'ERROR' ? 0 : 1;
            }

            messages.push({
                total: error.total,
                message: message,
                entity: error.entity,
                id:
                    (error.item && error.item.id) ||
                    (error.subscription && error.subscription.id) ||
                    error.entityId,
                subscription: error.subscription,
            });
        }, this);

        return messages;
    }

    public getLiteral(key: any): string {
        let ret: string = '';

        if (this.isLiteralDefined(key)) {
            ret = this.translate(key);
        } else {
            key = key + '.$name';
            if (this.isLiteralDefined(key)) {
                ret = this.translate(key);
            }
        }

        if (intNumberRe.test(ret)) {
            let tokens = key.split('.');
            tokens.pop();
            tokens.push(ret);
            ret = this.getLiteral(tokens.join('.'));
        }

        return ret;
    }

    /**
     * Determines whether an error code is a valid code. Returns true if valid and
     * false otherwise.
     *
     *
     * @param {*} code
     * @returns
     * @memberof ErrorService
     */
    public isValidErrorCode(code: string | number): boolean {
        let result = true;

        if (code == null) {
            return false;
        }

        if (!isString(code)) {
            code = code.toString();
        }

        if (code.length !== 7 || Number.isNaN(Number.parseInt(code, 10))) {
            result = false;
        }

        if (
            code.length === 8 &&
            ['svc_', 'svr_'].includes(code.substring(0, 4)) &&
            !Number.isNaN(Number.parseInt(code.substring(4, 8), 10))
        ) {
            result = true;
        }

        return result;
    }

    /**
     * Take a padded string as input of a certain length,
     * and returns the corresponding error literal, which is translatable, if exists.
     * Returns null otherwise.
     *
     * @param {*} code
     * @returns
     * @memberof ErrorService
     */
    public getErrorLiteral(code: string): string | null {
        let ret = null;
        const resolveLiteral = (code: string) => {
            const literal = interpolate(this.constant.codeNS, code);
            return this.translationService.exists(literal) ? literal : null;
        };

        if (code) {
            const codeStr = this.code2String(code);
            if (this.isValidErrorCode(codeStr)) {
                // Check if an error with 7 digits exists
                if (codeStr.length === 7) {
                    ret =
                        resolveLiteral(codeStr) ||
                        resolveLiteral(codeStr.slice(0, 5));
                } else if (codeStr.length === 8) {
                    ret = resolveLiteral(
                        codeStr.substring(0, 3) + '.' + codeStr.substring(4, 8)
                    );
                }
            }
        }

        return ret;
    }

    /**
     * Generic (application-wide) error message window.
     *
     * @param {String} message
     * @param {Function} [onOkFn] callback function called when the window is accepted.
     * @param {String} [title] title. Defaults to __('MsgBox.errorTitle').
     * @this Object
     */
    public showErrorMessage(
        message: string,
        onOkFn: Function | undefined,
        title: string | undefined,
        extraOptions?: any
    ): void {
        const btnOkText =
            extraOptions?.btnOkText || this.translate('MsgBox.btnAccept');
        this.showMessage(
            message,
            title || this.translate('MsgBox.errorTitle'),
            onOkFn,
            undefined,
            btnOkText
        );
    }

    /**
     * Generic (application-wide) question message window.
     *
     * @param {*} message
     * @param {*} title Defaults to __('MsgBox.errorTitle').
     * @param {*} onOkFn callback function called when the window is accepted.
     * @param {*} onCancelFn callback function called when the window is canceled.
     * @memberof ErrorService
     */
    public showQuestionMessage(
        message: string,
        title: string,
        onOkFn: Function,
        onCancelFn: Function
    ): void {
        this.showMessage(
            message,
            title || this.translate('MsgBox.errorTitle'),
            onOkFn,
            onCancelFn,
            this.translate('MsgBox.btnYes'),
            this.translate('MsgBox.btnNo')
        );
    }

    public getUserUploadErrorMessage(errorData: any): string {
        const MAX_ERRORS = 10;
        let userMessage = '';

        if (errorData.failed) {
            let errors = null;
            if (!Array.isArray(errorData.failed)) {
                errors = [errorData.failed];
            } else {
                errors = [...errorData.failed];
            }
            let i = 0;
            for (; i < errors.length && i < MAX_ERRORS; i++) {
                userMessage += `<br><span class="upload-process-error-line">${this.getUploadErrorText(errors[i])}</span>`;
            }
            if (i < errors.length) {
                userMessage +=
                    '<br><br><span class="upload-process-error-line">...</span>';
            }
        }

        return userMessage;
    }

    private isLiteralDefined(key: any): boolean {
        return this.translationService.exists(key);
    }

    private showMessage(
        message: string,
        title: string,
        onOkFn?: Function,
        onCancelFn?: Function,
        yesBtnTxt?: string,
        noBtnTxt?: string
    ): void {
        // Open mat-dialog
        this.matDialog
            .open(DialogComponent, {
                width: DEFAULT_DIALOG_WIDTH,
                data: {
                    title: title || this.translate('MsgBox.errorTitle'),
                    closeText: noBtnTxt,
                    submitText: yesBtnTxt,
                    description: message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.action === 'submit' && onOkFn) {
                    onOkFn();
                } else if (result.action === 'close' && onCancelFn) {
                    onCancelFn();
                }
            });
    }

    /**
     * Get the error text considering parameters
     *
     * @param {*} error
     * @returns
     */
    private getUploadErrorText(error: any): string | null {
        let errorText: string | null = '';
        if (error && error.code) {
            // Error codes come with 5 digts, but error service requires 7
            const REQUIRED_ERROR_LENGTH = 7;
            let errorCode = `${error.code}`;
            if (errorCode.length < REQUIRED_ERROR_LENGTH) {
                errorCode = `${errorCode}00`;
            }
            errorText = this.getMessageFromCode(errorCode, error.params);
        }

        return errorText;
    }
}
