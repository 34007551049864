import { AppConfigService } from '../app-config/app-config.service';
import { LoadScripOptions, ScriptLoaderService } from '../script-loader/script-loader.service';
import { Inject, Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { DOCUMENT } from '@angular/common';
import { I18nService } from '../i18n/i18n.service';

@Injectable({
    providedIn: 'root'
})
export class OneTrustService {
    private oneTrustKey: string | undefined;
    private templateScriptUrl: string = '';
    private consentScriptUrl: string = '';

    constructor(
        private window: WindowRefService,
        private appConfigService: AppConfigService,
        private scriptLoaderService: ScriptLoaderService,
        private i18nService: I18nService,
        @Inject(DOCUMENT) private document: Document) {

        this.appConfigService.getAsync<string>('oneTrustKey').subscribe((key: string) => {
            this.oneTrustKey = key;
            this.templateScriptUrl = this.appConfigService.get('oneTrustScriptTemplateUrl');
            this.consentScriptUrl = this.appConfigService.get('oneTrustScriptConsentUrl');
        });
    }

    /**
     * Appends script tags to the document to load the API script.
     * @memberof OneTrustService
     */
    public loadScript(): Promise<void> {
        if (!this.oneTrustKey || !this.templateScriptUrl || !this.consentScriptUrl || this.isLoaded()) {
            return Promise.resolve();
        }
        const lang = this.i18nService.getLanguage();
        return this.appendScriptTags(lang);
    }

    public openOneTrustConfig(): void {
        const btnElement = this.document.getElementById('ot-sdk-btn');
        if (btnElement) {
            btnElement.click();
        }
    }

    /**
     * Append script tags to the begining of the head section
     *
     * @memberof OneTrustService
     */
    private appendScriptTags(lang: string): Promise<void> {
        const consentScriptOptions: LoadScripOptions = {
            src: this.consentScriptUrl,
            id: 'one-trust-script-1',
        };

        const templateScriptOptions: LoadScripOptions = {
            src: this.templateScriptUrl,
            id: 'one-trust-script-2',
            attributes: {
                'data-document-language': false,
                'data-language': lang,
                'charset': 'UTF-8',
                'data-domain-script': this.oneTrustKey,
            },
        };

        return this.scriptLoaderService.loadScript(consentScriptOptions)
            .then(() => this.scriptLoaderService.loadScript(templateScriptOptions))
            .then(() => {
                this.window.nativeWindow.OptanonWrapper = function OptanonWrapper() {
                    // This function is necessary for the onetrust script to work
                };
            })
            .catch(() => {
                // Handle error and return a resolved promise
                // to avoid breaking the chain and avoid the app to crash
                return Promise.resolve();
            });
    }

    /**
     * Check whether one-trust scripts has been loaded yet.
     *
     * @returns
     * @memberof OneTrustService
     */
    private isLoaded(): boolean {
        const scripts = this.document.scripts;
        const scriptArr = [].slice.call(scripts);
        return scriptArr.some((elem: any) =>
            elem && (elem['data-domain-script'] || elem.getAttribute('data-domain-script')));
    }
}
