/**
 * Creates and returns an array containing [0, 1, ..., n-2, n-1] elements.
 *
 * @example
 *  arrayKeys(5)   // [0,1,2,3,4]
 *  arrayKeys(10)  // [0,1,2,3,4,5,6,7,8,9]
 *
 * @export
 * @param {*} n
 * @returns
 */
export function arrayOfKeys(n: number): number[] {
    if (typeof n !== 'number' || isNaN(n) || Math.floor(n) !== n) {
        throw new Error(`Not a integer`);
    }
    return Array
        .apply(null, { length: n } as any)
        .map(Number.call, Number) as number[];
}
