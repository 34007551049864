import 'angular';
import 'angular-material';
import 'angular-sanitize';
import 'angular-cookies';
import 'angular-messages';
import '@uirouter/angularjs';
import 'oclazyload';
import 'vendors/nitro-components/nitro-components';

import { BigInteger } from 'jsbn';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

window.BigInteger = BigInteger;
window._.mixin(window.s.exports());

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));
