/**
 * Accepts an array of callbacks that returns a promise each and process them
 * in a serial or sequential manner.
 *
 * @export
 * @param {*} [callbacks=[]]
 * @return {*}
 */
export function runPromisesInSeries(callbacks = []){
    return callbacks.reduce((acc, next) => {
        acc = acc.then(next);
        return acc;
    }, Promise.resolve());
}
