import { cultureInfoResolver } from './app/services/i18n/culture-info-resolver';
import packageJson from '../package.json';
import { StylesManagerService } from '@services/styles-manager/styles-manager.service';
import { AppInitializationContext } from '@shared/interfaces';
import { ScriptLoaderService } from '@services/script-loader/script-loader.service';
import { setCustomFavicon } from './app/common/app-config-utils/custom-favicon';
import { AppConfigService } from './app/services/app-config/app-config.service';
import { ThemeVarsService } from './app/services/themes/theme-vars.service';

declare const __WEBPACK_VAR_ENV: any;
declare const __WEBPACK_VAR_PACKAGE_VERSION: any;
declare const __WEBPACK_VAR_COMPILE_TIME: any;
declare const __WEBPACK_VAR_IS_ANGULAR_BUILD: any;
declare const __WEBPACK_VAR_CUSTOM_TRANSLATION_IDS: any;
declare const __WEBPACK_VAR_KITE_ENV: string;
declare const __WEBPACK_VAR_BRANCH_NAME: string;

window['__WEBPACK_VAR_ENV'] = __WEBPACK_VAR_ENV;
window['__WEBPACK_VAR_PACKAGE_VERSION'] = __WEBPACK_VAR_PACKAGE_VERSION;
window['__WEBPACK_VAR_COMPILE_TIME'] = __WEBPACK_VAR_COMPILE_TIME;
window['__WEBPACK_VAR_IS_ANGULAR_BUILD'] = __WEBPACK_VAR_IS_ANGULAR_BUILD;
window['__WEBPACK_VAR_CUSTOM_TRANSLATION_IDS'] = __WEBPACK_VAR_CUSTOM_TRANSLATION_IDS;
window['__WEBPACK_VAR_KITE_ENV'] = __WEBPACK_VAR_KITE_ENV;
window['__WEBPACK_VAR_BRANCH_NAME'] = __WEBPACK_VAR_BRANCH_NAME;


export function bootstrap(appConfig: any, themes: string[]): Promise<AppInitializationContext> {
    const appVersion = packageJson.version;
    const appName = 'kiteApp';
    const stylesManager = new StylesManagerService(window.document, {} as AppConfigService, {} as ThemeVarsService);
    const scriptManager = new ScriptLoaderService(window.document);

    // resolve app theme from url params or use default theme.
    const urlParams = new URLSearchParams(window.location.search);
    const urlTheme = urlParams.get('theme') || '';
    const theme = themes.includes(urlTheme) ? urlTheme : appConfig.theme;

    const cultureInfo = cultureInfoResolver(appConfig.culture.supported, appConfig.culture.fallback);

    // update the document with current theme class.
    document.documentElement.classList.add(`theme__${theme}`);

    setTimeout(() => setCustomFavicon(theme), 0);

    return Promise.all([
        import(/* webpackChunkName: 'ng1-app-module' */ './app/ng1-app.module').then(module => module.Ng1AppModuleFactory),
        loadHeaderImageUrls(theme),
        scriptManager.loadScript({ src: 'ag-grid-enterprise.js', id: 'ag-grid-enterprise' }),
        stylesManager.setStyle('app_vars', `vars_${theme.toLowerCase()}.css`),
        stylesManager.setStyle('app_styles', `app_${theme.toLowerCase()}.css`),
        stylesManager.setStyle('m2m_styles', `m2m_${theme.toLowerCase()}.css`),
    ])
        .then(([Ng1AppModuleFactory, headerImageUrls = []]) => {
            const ctx: AppInitializationContext = {
                appName,
                appVersion,
                cultureInfo,
                appConfig,
                Ng1AppModuleFactory,
                headerImageUrls,
            };
            return ctx;
        })
        .catch((err: any) => {
            window.TID.log.error(err);
            return showErrorMessage()
                .then(() => bootstrap(appConfig, themes))
                .catch(() => Promise.reject('Oops! Something went wrong.\nPlease reload the page.'));
        });
}

// Utilities
function showErrorMessage() {
    return new Promise((resolve, reject) => {
        window.TID.log.warn('There was a problem loading i18n files, aborting application...');
        window.BoxMessage.open({
            title: window.Kermit.i18n.getText('MsgBox.errorTitle', 'Error'),
            message: window.Kermit.i18n.getText('MsgBox.errorInitLoad',
                'There was an error in the initial load of the portal.<br><br>' +
                'Do you want to retry the load?'),
            confirmModal: true,
            buttonTextCancel: window.Kermit.i18n.getText('MsgBox.btnYes', 'Yes'),
            buttonTextOk: window.Kermit.i18n.getText('MsgBox.btnNo', 'No'),
            button: {
                close: {
                    cls: 'accept',
                    callback: resolve,
                },
                accept: {
                    cls: 'cancel',
                    callback: reject,
                },
            },
        });
    });
}

function loadHeaderImageUrls(currentTheme: string):
    Promise<{ headerImg: string, contentImg: string, textImg: string }> {
    const imageUrls = [
        import(
            /* webpackPrefetch: true */
            /* webpackInclude: /(movistar|vivo|base|windhellas|O2_uk_local|O2_germany|O2_germany_local|tigo|ttech)/ */
            `/src/styles/images/header/${currentTheme}-header-bg@2x.png`),

        import(
            /* webpackPrefetch: true */
            /* webpackInclude: /(movistar|vivo|base|windhellas|O2_uk_local|O2_germany|O2_germany_local|tigo|ttech)/ */
            `/src/styles/images/header/${currentTheme}-logo.svg`)
    ];

    if (currentTheme !== 'base') {
        imageUrls.push(import('/src/styles/images/header/kite-platform-logo.svg'))
    }

    return Promise.all(imageUrls)
        .then(responses => ({
            headerImg: responses[0]?.default,
            contentImg: responses[1]?.default,
            textImg: responses[2]?.default,
        }));
}
