/**
 * Determines the type of transition event available in browser.
 *
 * @returns
 */
export function getTransitionEndEvent() {
    let t: string;
    const el = document.createElement('fakeelement');
    const transitions: { [key: string]: string } = {
        'transition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'MozTransition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd',
    };

    for (t in transitions) {
        if ((el.style as any)[t] !== undefined) {
            return transitions[t];
        }
    }
    return undefined;
}
