import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface LoadScripOptions {
    src: string;
    type?: string;
    async?: boolean;
    defer?: boolean;
    id?: string;
    text?: string;
    class?: string;
    attributes?: Record<string, any>;
};

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    scripts: Record<string, boolean> = {};

    constructor(@Inject(DOCUMENT) private document: Document) {}

    loadScript(options: LoadScripOptions): Promise<void> {
        // prevent duplicate script loading
        return new Promise<void>((resolve, reject) => {
            if (!options.src) {
                throw new Error('ScriptLoaderService.loadScript requires a source');
            }
            const versionParam = `${options.src.includes('?') ? '&' : '?'}v=${window.__WEBPACK_VAR_COMPILE_TIME}`;
            const versionedSrc = `${options.src}${versionParam}`;

            if (this.scripts[versionedSrc]) {
                resolve();
                return;
            }

            const script:HTMLScriptElement = this.document.createElement('script');
            script.type = options.type || 'text/javascript';
            script.async = options.async || false;
            script.defer = options.defer || false;
            script.src = versionedSrc;

            if (options.id) {
                script.id = options.id;
            }

            if (options.text) {
                script.text = options.text;
            }

            if (options.class) {
                script.classList.add(options.class);
            }

            const attributes = options.attributes;
            if (attributes) {
                for (const attribute in attributes) {
                    if (attributes.hasOwnProperty(attribute)) {
                        script.setAttribute(attribute, attributes[attribute]);
                    }
                }
            }

            script.onload = () => {
                this.scripts[versionedSrc] = true;
                resolve();
            };

            script.onerror = () => {
                this.scripts[versionedSrc] = false;
                reject(new Error(`Failed to load ${versionedSrc}`));
            };

            this.document.head.appendChild(script);
        });
    }

    removeScriptsById(id: string): void {
        Array.from(this.document.scripts)
            .filter(s => s.id === id)
            .forEach((s: HTMLScriptElement) => s.parentElement?.removeChild(s));
    }
}
