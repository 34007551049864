/**
 * Sorter function for sorting numerical values.
 *
 * @export
 * @param {*} a
 * @param {*} b
 * @return {*}
 */
export function sortNumerical<T = any>(valueA: T, valueB: T): number {
    if (valueA < valueB) {
        return -1;
    } else if (valueA > valueB) {
        return 1;
    } else {
        return 0;
    }
}
