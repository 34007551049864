/**
 * Factory that returns a resize event handler to effectively resize a SVG element.
 */
export function resizeHandleFactory(
    container: HTMLElement,
    svg: any,
    aspect: number
): () => void {
    return () => {
        const bounds = container.getBoundingClientRect();
        const containerHeight = bounds.height;
        const newWidth = Math.round(containerHeight * aspect);

        svg.attr('width', newWidth);
        svg.attr('height', containerHeight);
    };
}
