import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FormatService {
    /**
     * Regular expression that matches any character that is not a word character.
     * RegExp is case insensitive
     *
     * @memberof FormatService
     */
    static nonWord = /\W/gi;

    /**
     * RegExp that matches any of the characters present in the array.
     * It is case insensitive.
     *
     * @memberof FormatService
     */
    static notAllowedChars = /[æçñœàáâãäåèéêëìíîïòóôõöùúûüýÿ]/gi;

    /**
     * Map of characters that should be filtered out from input string
     *
     * @memberof FormatService
     */
    static map: any = {
        'æ': 'ae',
        'Æ': 'AE',
        'œ': 'oe',
        'Œ': 'OE',
        'ç': 'c',
        'Ç': 'C',
        'ñ': 'n',
        'Ñ': 'N',
        'ý': 'y',
        'ÿ': 'y',
        'Ý': 'Y',
        'Ÿ': 'Y',
        'à': 'a',
        'á': 'a',
        'â': 'a',
        'ã': 'a',
        'ä': 'a',
        'å': 'a',
        'À': 'A',
        'Á': 'A',
        'Â': 'A',
        'Ã': 'A',
        'Ä': 'A',
        'Å': 'A',
        'è': 'e',
        'é': 'e',
        'ê': 'e',
        'ë': 'e',
        'È': 'E',
        'É': 'E',
        'Ê': 'E',
        'Ë': 'E',
        'ì': 'i',
        'í': 'i',
        'î': 'i',
        'ï': 'i',
        'Ì': 'I',
        'Í': 'I',
        'Î': 'I',
        'Ï': 'I',
        'ò': 'o',
        'ó': 'o',
        'ô': 'o',
        'õ': 'o',
        'ö': 'o',
        'Ò': 'O',
        'Ó': 'O',
        'Ô': 'O',
        'Õ': 'O',
        'Ö': 'O',
        'ù': 'u',
        'ú': 'u',
        'û': 'u',
        'ü': 'u',
        'Ù': 'U',
        'Ú': 'U',
        'Û': 'U',
        'Ü': 'U',
    };

    /**
     * Replaces not allowed characters from the input string and return a new string.
     * @param {*} input
     * @returns
     * @memberof FormatService
     */
    public accentsTidy(input: string): string {
        return input
            .replace(FormatService.notAllowedChars, (match: any) => FormatService.map[match] || match)
            .replace(FormatService.nonWord, '');
    }
}
