/**
 * Find all indexes of the array passed in that fullfill the equity condition
 * with the val passed in as parameter.
 *
*/
export function findIndexes(
    arr: any[],
    compareFn: (val: any, index: number, arr: any[]) => boolean
    ): number[] {
    let indexes: number[] = [];
    for (let i = 0, len = arr.length; i < len; i++) {
        if(compareFn(arr[i], i, arr)) {
            indexes.push(i);
        }
    }
    return indexes;
}
