/**
 * Genera un identificador UUID.
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 * https://caniuse.com/#search=crypto
 *
 * @returns {string}
 */
export function uuidv4(): string {
    const crypto = window.crypto || (window as any).msCrypto;
    // Fallback for IE<11, use Array if Uint8Array is not supported
    const ARRAY_TYPE: any = ('Uint8Array' in window) ? Uint8Array : Array;
    return (`${[1e7]}${-1e3}${-4e3}${-8e3}${-1e11}`).replace(/[018]/g, (c: string) =>
        (parseInt(c) ^ crypto.getRandomValues(new ARRAY_TYPE(1))[0] & 15 >> parseInt(c) / 4).toString(16)
    );
}
