import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { Transition } from '@uirouter/core';
import { UpgradeModule } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root'
})
export class MenuRouteEventHelper {
    private _subject: ReplaySubject<Transition> = new ReplaySubject<Transition>(1);
    public transitionSuccess$: Observable<Transition> = this._subject.asObservable();

    public initialize(upgrade: UpgradeModule): void {
        // Forward success transitions events to a subscriber so they can be process later after
        // they have happened. There is a condition when state have successfully transition but
        // the sidebar menu component is not fully ready to listen for this types of events.
        // I am using this event to update selected section in the sidebar menu.
        if (upgrade) {
            const $transitions = upgrade.$injector.get('$transitions');
            $transitions.onSuccess({}, (t: Transition) => this._subject.next(t));
        } else {
            throw new Error('upgrade is not defined');
        }
    }
}
