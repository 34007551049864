export const ACCOUNT_URLS_QUERY_PARAMS = {
    r_url: null,
    searchParams: null,
    token: null,
    userName: null,
    rc: null,
    lang: null,
    mor: null,
    userId: null,
};
