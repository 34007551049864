import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpCommonHeadersService } from '../services/http/common-headers.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private httpCommonHeaders: HttpCommonHeadersService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const headers = this.httpCommonHeaders.getCommonHeaders();
        return next.handle((headers !== null) ? req.clone({ setHeaders: headers }) : req);
    }
}
