import { isString } from 'lodash-es';

/**
 * Returns one of the following formats to timestamp
 * YYYY-MM-DD
 * YYYYMMDD
 * YYYY-MM
 * YYYYMM
 *
 * @param {*} value
 * @returns
 */
export function dateToEpoch(value?: string): number | undefined {
    if (isString(value)) {
        const parsedVal = value.replace(/[^A-Z0-9]+/ig, "");
        const year = parsedVal.substring(0, 4);
        const month = parsedVal.substring(4, 6);
        const day = parsedVal.length > 6 ? parsedVal.substring(6, 8) : '01';
        return (new Date(parseInt(year), parseInt(month) - 1, parseInt(day))).getTime() / 1000;
    }
    return undefined;
}

/**
 * Convert a string that represent a date into same date with dash.
 *
 * @example
 *  "20200101" will be transform into "2020-01-01"
 *
 * @export
 * @param {*} str
 * @returns
 */
export function dateStrDashed(str: string): string | undefined {
    if (isString(str) && str.length === 8) {
        return `${str.substring(0, 4)}-${str.substring(4, 6)}-${str.substring(6, 8)}`;
    }
    return undefined;
}


/**
 *
 *
 * @export
 * @param {*} items
 * @returns
 */
export function dateIndex(items: any[]){
    return items
        .map(item => ({ epoch: dateToEpoch(item.date), date: item.date }))
        .sort((a: any, b: any) => (a.epoch > b.epoch) ? 1 : -1);
}
