import { isNumber } from '/src/app/shared/utils/numbers/is-number';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppVersionService } from '../services/app-version/app-version.service';
import { WindowRefService } from '../services/window-ref/window-ref.service';
import { BoxMessageService } from '../services/box-message/box-message.service';
import { AppConfigService } from '../services/app-config/app-config.service';

@Injectable()
export class ResponseLowerVersionInterceptor implements HttpInterceptor {

    constructor(
        private appVersion: AppVersionService,
        private window: WindowRefService,
        private upgrade: UpgradeModule
    ) {}

    minorVersionThan(releaseVersion: any, appVersion: AppVersionService): boolean {
        let ret = false;
        // Missing a version digit it is assumed 0
        const appMajor = appVersion.major || 0;
        const respMajor = releaseVersion.major || 0;
        if (respMajor < appMajor) {
                ret = true;
        } else if (respMajor === appMajor) {
            const appMinor = appVersion.minor || 0;
            const respMinor = releaseVersion.minor || 0;
            if (respMinor < appMinor) {
                ret = true;
            } else if (respMinor === appMinor) {
                const appPatch = appVersion.patch || 0;
                const respPatch = releaseVersion.patch || 0;
                if (respPatch < appPatch) {
                    ret = true;
                }
            } else {
                // SONAR
            }
        } else {
            // SONAR
        }
        return ret;
    }

    getInteger(numberString: string): number {
        let retValue = -1;
        // Avoid undefined, null, and '';
        if (isNumber(numberString)) {
            retValue = Number(numberString);
        }

        return retValue;
    }

    /**
     * Takes first received version
     * For each request, when version received, only set values and compare funcion if new version differs from inital
     *
     * @param {*} headers
     * @returns
     */
    getReleaseVersion(headers: HttpHeaders): any {
        const releaseVersion = headers.get('release-version');
        if (releaseVersion) {
            if (!this.appVersion.backendReleaseVersion) {
                this.appVersion.backendReleaseVersion = releaseVersion;
            } else if (releaseVersion !== this.appVersion.backendReleaseVersion){
                const majorPos = 0;
                const minorPos = 1;
                const patchPos = 2;
                const firstCodePos = 3;
                const secondCodePos = 4;

                const split = releaseVersion.split('_');
                const result = {
                    value: {
                        full: releaseVersion,
                        major: this.getInteger(split[majorPos]),
                        minor: this.getInteger(split[minorPos]),
                        patch: this.getInteger(split[patchPos]),
                        codeName: `${split[firstCodePos]}_${split[secondCodePos]}`
                    }
                };
                return result;
            } else {
                // SONAR
            }
        }
        return undefined;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                switchMap(event => {
                    if (event instanceof HttpResponse) {
                        const config = this.upgrade.$injector.get('appConfigService') as AppConfigService;
                        const boxMessage = this.upgrade.$injector.get('boxMessage') as BoxMessageService;

                        // This control can not be applied on app-interceptros.config because it has not access to config yet
                        if (config.get('applyVersionControl')) {
                            const releaseVersion = this.getReleaseVersion(event.headers);
                            if (releaseVersion && this.minorVersionThan(releaseVersion, this.appVersion)) {
                                return from(boxMessage.notifyErrorAndReload())
                                    .pipe(map(() => event), catchError(() => of(event)));
                            }
                        }
                    }
                    return of(event);
                }),
            );
    }

}
